<script type="text/ecmascript-6">
    import MainLayout from '../layouts/Main.vue'
    import listingService from '../services/listing'
    import Tabs from '../components/Tabs.vue'
    import tabsMixin from '../mixins/tabs'
    import categoryService from '@/services/category.js'
    import brandService from '@/services/brand.js'
    import StandardModal from '../components/StandardModal.vue'
    import ListingStatus from '../components/ListingStatus.vue'

    import offerService from '../services/offer'

    export default {
        components: { MainLayout, StandardModal, ListingStatus, Tabs},
        mixins: [tabsMixin],
        data() {
            
            return {

                listings: [],
                listing: {},
                categories: [],
                brands: [],
                acceptedListing: null,
                refusedListing: null,
                refusedMessage: "",
                listingTotalPrice: 0,
                isLoading:false,
                listingPhotoClicked:null,
                photoToZoom: null,
                listingPhotosUpdated: [],
                tabs: [
                    {
                        id: 'to_validate',
                        label: 'Annonces à valider',
                        isActive: false
                    },
                    {
                        id: 'accepted',
                        label: 'Annonces acceptées',
                        isActive: false
                    },
                    {
                        id: 'refused',
                        label: 'Annonces refusées',
                        isActive: false
                    },
                ],
                photosLoading: false,
                simulatedPrices: null,
                isSimulatedPriceLoading: false,
                countListing: null,
                sizePreview: null,
                deviationPercentage: null
            }
        },
        methods: {

            async showListing() {

                try {

                    let response = await listingService.show(this.listingPublicId)
                    this.listing = response.data
                }
                catch(e) {

                    console.log(e);
                }

                this.toggleTabs(this.tabs, this.listing.status)

            },
            async loadCategories() {

                let response = await categoryService.list(1)
                this.categories = response.data
            },
            async loadBrands() {

                let response = await brandService.list()
                this.brands = response.data
            },
            brandName(brandId) {

                let brandFiltered = this.brands.find(brand => brand.id === brandId)

                return brandFiltered != undefined ? brandFiltered.name : ''
            },
            categoryName(categoryId) {

                let categoryFiltered = this.categories.find(category => category.id === categoryId)

                return categoryFiltered != undefined ? categoryFiltered.name : ''
            },
            acceptListing(listing) {

                this.acceptedListing = listing
            },
            refuseListing(listing) {

                this.refusedListing = listing
            },
            cleanAcceptedListing() {

                this.acceptedListing = null
                this.listingTotalPrice = 0
            },
            cleanRefusedListing() {

                this.refusedListing = null
                this.refusedMessage = ""
            },
            cleanlistingPhotoClicked() {

                this.listingPhotoClicked = null
            },
            async confirmRefuseListing() {

                this.isLoading = true
                if (this.isRefusedMessageLengthOK) {

                    await listingService.refuse(this.refusedMessage, this.listingPublicId)
                    this.cleanRefusedListing()
                    this.showListing()
                    this.isLoading = false
                }
            },
            async confirmAcceptListing() {

                this.isLoading = true
                    if (this.isAcceptedListingPriceOK) {

                    await listingService.accept(this.listingTotalPrice, this.listingPublicId)
                    this.cleanAcceptedListing()
                    this.showListing()
                    this.isLoading = false
                }
            },
            async loadPhotos() {

                this.photosLoading = true
                let response = await listingService.getPhotos(this.listingPublicId)
                this.listingPhotos = response.data

                this.listingPhotos.forEach(element => {
                    
                    let fileDetails = this.getTypeFile(element.charAt(0))

                    let preview = "data:" + fileDetails.dataType + ";base64," + element
                    let size =  fileDetails.sizePreview
                    this.listingPhotosUpdated.push({size,preview})
                });
                this.photosLoading = false

            },
            getTypeFile(firstLetter) {

                var fileDetails = {}

                switch (firstLetter)
                {
                    case 'J' : 

                        fileDetails = {

                            dataType: 'application/pdf',
                            extension:'pdf',
                            sizePreview: 'width:500px; height:500px'
                        }
                        
                        break;

                    case 'i' : 

                        fileDetails = {

                            dataType: 'image/png',
                            extension:'png',
                            sizePreview: 'max-width:500px; max-height:500px'
                        }

                        break;

                    case '/' : 

                        fileDetails = {

                            dataType: 'image/jpeg',
                            extension:'jpeg',
                            sizePreview: 'max-width:500px; max-height:500px'
                        }
                        break;

                    case 'U' : 

                        fileDetails = {

                            dataType: 'image/jpeg',
                            extension:'webp',
                            sizePreview: 'max-width:500px; max-height:500px'
                        }
                        break;
                    
                }

                return fileDetails
            },
            async loadListings() {

                let response = await listingService.list(this.currentPage, ['to_validate', 'accepted', 'refused'])
                this.listings = response.data.data 
                this.lastPage = response.data.last_page
            },
            async refreshPrice(listing) {

                this.isSimulatedPriceLoading = true
                let response = await offerService.simulatePrice(this.listingTotalPrice * 100, listing.category_weight, listing.reseller_delivery_type, listing.merchant_id)
                this.simulatedPrices = response.data
                this.isSimulatedPriceLoading = false

                if (this.listing.recommended_price != null) {

                    this.deviationPercentage = Math.round(this.listingTotalPrice * 100 / this.listing.recommended_price * 100)
                }
            },
            async loadCount() {

                let response = await listingService.count() 
                this.countListing = response.data
            },
            zoomPhoto(preview) {

                let fileDetails = this.getTypeFile(preview.charAt(0))

                this.listingPhotoClicked = preview
                this.sizePreview = fileDetails.sizePreview
            },
        },
        created() {

            this.loadListings()
            this.showListing()
            this.loadBrands()
            this.loadCategories()
            this.loadPhotos()
            this.loadCount()
        },
        computed: {

            listingPublicId() {

                return this.$route.params.listingPublicId ? this.$route.params.listingPublicId : null
            },
            isRefusedMessageLengthOK() {

                return this.refusedMessage.length > 10
            },
            isAcceptedListingPriceOK() {

                return (this.simulatedPrices && this.simulatedPrices.price != 0)
            },
            listingPhotoToZoom() {

                return "data:image/png;base64," + this.listingPhotoClicked
            },
            listingsToValidate() {

                return this.listings.filter(listing => listing.status === 'to_validate')
            },
            listingsAccepted() {

                return this.listings.filter(listing => listing.status === 'accepted')
            },
            listingsRefused() {

                return this.listings.filter(listing => listing.status === 'refused')
            },
            countListingToValidate() {

                return this.countListing != null ? this.countListing.to_validate : 0
            },
            countListingAccepted() {

                return this.countListing != null ? this.countListing.accepted : 0
            },
            countListingRefused() {

                return this.countListing != null ? this.countListing.refused : 0
            },
            isMonitor() {

                if (this.listing && this.listing.category_id == 4) return true

                return false
            }
        },
    }
</script>

<template>
    <main-layout>
        <div class="d-flex m-3 align-items-center">
            <h3 class="m-3"><strong>{{ listing.public_id }}</strong></h3>
            <listing-status style="height: 20px;" :listing="listing"/> 
        </div>
        <tabs v-model="tabs" :page="'ShowListing'"/>
        <div class="row mx-n2 pt-2">
            <div class="col-md-2 col-sm-6 px-2 mb-4">
                <router-link :to="{name: 'listings', params:{tab: 'to_validate'}}" v-on:click="toggleTabs(tabs, 'to_validate')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'to_validate'), 'border-primary': isTabActive(tabs, 'to_validate')}">
                        <h3 class="font-size-sm text-muted">Annonces à valider</h3>
                        <p class="h2 mb-2 text-success">{{countListingToValidate}}</p>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'to_validate')">Voir le détail</p>
                    </div>
                </router-link>
            </div>
            <div class="col-md-2 col-sm-6 px-2 mb-4">
                <router-link :to="{name: 'listings', params:{tab: 'accepted'}}" v-on:click="toggleTabs(tabs, 'accepted')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'accepted'), 'border-primary': isTabActive(tabs, 'accepted')}">
                        <h3 class="font-size-sm text-muted">Annonces acceptées</h3>
                        <p class="h2 mb-2 text-success">{{countListingAccepted}}</p>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'accepted')">Voir le détail</p>
                    </div>
                </router-link>
            </div>
            <div class="col-md-2 col-sm-6 px-2 mb-4">
                <router-link :to="{name: 'listings', params:{tab: 'refused'}}" v-on:click="toggleTabs(tabs, 'refused')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'refused'), 'border-primary': isTabActive(tabs, 'refused')}">
                        <h3 class="font-size-sm text-muted">Annonces refusées</h3>
                        <p class="h2 mb-2 text-success">{{countListingRefused}}</p>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'refused')">Voir le détail</p>
                    </div>
                </router-link>
            </div>
        </div>
        <div v-if="isMonitor" class="alert alert-danger">Merci de <strong>REFUSER</strong> si c'est une <strong>TV</strong>. Si l'annonce est acceptée quand même nous devrons vous facturer au réél la logistique.</div>
        <div class="d-flex align-items-start flex-column flex-md-row">

            <table class="table table-bordered font-size-sm bg-white col-md-4 m-1">
                <tbody>
                    <tr>
                        <th>Public ID</th>
                        <td class="align-middle">{{listing.public_id}}</td>
                    </tr>
                    <tr>
                        <th>Modèle</th>
                        <td class="align-middle">{{listing.model}}</td>
                    </tr>
                    <tr v-if="listing.status === 'accepted'">
                        <th>Votre offre</th>
                        <td class="align-middle">{{listing.total_price/100}} €</td>
                    </tr>
                    <tr v-else-if="listing.status === 'refused'">
                        <th>Raison de votre refus</th>
                        <td class="align-middle">{{listing.refused_reason}} </td>
                    </tr>
                    <tr>
                        <th>Marque</th>
                        <td v-if="brands.length > 0" class="align-middle">{{brandName(listing.brand_id)}}</td>
                    </tr>
                    <tr>
                        <th>Catégorie</th>
                        <td v-if="categories.length > 0" class="align-middle">{{categoryName(listing.category_id)}}</td>
                    </tr>
                    <tr>
                        <th>Condition</th>
                        <td class="align-middle">{{listing.product_condition_name}}</td>
                    </tr>
                    <tr>
                        <th>Détails</th>
                        <td class="align-middle">{{listing.details}}</td>
                    </tr>
                    <tr>
                        <th>Année</th>
                        <td class="align-middle">{{listing.year}}</td>
                    </tr>
                    <tr v-if="listing.status === 'to_validate'">
                        <th>Actions</th>
                        <td class="align-middle text-right d-flex justify-content-around">
                            <button type="button" class="btn btn-sm btn-success" @click="acceptListing(listing)">Faire une offre</button>
                            <button type="button" class="btn btn-sm btn-danger ml-1" @click="refuseListing(listing)">Refuser</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-bordered font-size-sm bg-white  m-1">
                <tbody>
                    <tr>
                        <td class="align-middle">
                            <div class="row p-2 d-flex">
                            <span v-if="photosLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                <div :key="index" v-for="(preview, index) in listingPhotosUpdated" class="row p-2 d-flex flex-row">
                                    <div @click="zoomPhoto(preview.preview)">
                                        <object :style="preview.size" class="m-2 border col-12" :data="preview.preview"></object>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <standard-modal v-if="acceptedListing != null" :title="'Faire une offre pour l\'annonce : '  + acceptedListing.public_id" :closeLabel="'Retour'" @close="cleanAcceptedListing">
            <template v-slot:body>
                <p>Renseignez un prix afin d'accepter l'annonce : {{acceptedListing.model}} </p>
                <p v-if="listing.recommended_price != null">Offre recommandée : <strong>{{ listing.recommended_price != null ? $n(listing.recommended_price / 100, 'currency') : '0.00 €' }}</strong></p>
                <div class="form-inline mb-2">
                    <span for="listingTotalPrice">Votre offre :</span>
                    <input @change="refreshPrice(acceptedListing)" class="form-control text-center ml-2 mr-3" v-model="listingTotalPrice" id="listingTotalPrice" type="number" min="0" />
                    <p :class="{'text-danger' : deviationPercentage < 50, 'text-warning' : deviationPercentage > 50 && deviationPercentage < 75, 'text-success' : deviationPercentage > 75}" v-if="listing.recommended_price != null && deviationPercentage"> {{ deviationPercentage }} % du prix d'achat recommandé</p>
                </div>
                <p>Prix d'achat vendeur : <strong>{{ simulatedPrices != null ? $n(simulatedPrices.price / 100, 'currency') : '0.00 €' }}</strong></p>
                <span v-if="isSimulatedPriceLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                <div v-if="!isAcceptedListingPriceOK" class="alert alert-primary alert-sm mt-2" role="alert">
                    Votre offre est trop basse.
                </div>
            </template>
            <template v-slot:footer>
                <button :disabled="!isAcceptedListingPriceOK" role="button" class="btn btn-success" @click="confirmAcceptListing">
                    <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Valider l'offre
                </button>
            </template>
        </standard-modal>
        <standard-modal v-if="refusedListing != null" :title="'Refuser ' + refusedListing.public_id" :closeLabel="'Retour'" @close="cleanRefusedListing">
            <template v-slot:body>
                <div class="modal-body">
                    <p>
                        Refuser cette annonce <strong>"{{ refusedListing.model }}"</strong>
                    </p>
                    <div class="form-group">
                        <label for="refusedMessage">Veuillez indiquer la raison de votre refus:</label>
                        <textarea class="form-control" id="refusedMessage" v-model="refusedMessage" placeholder="Raison du refus"></textarea>
                    </div>
                    <div v-if="!isRefusedMessageLengthOK" class="alert alert-primary alert-sm mt-2" role="alert">
                        Merci de mettre au moins 10 caractères.
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <button  :disabled="!isRefusedMessageLengthOK" type="button" class="btn btn-primary" @click="confirmRefuseListing"><span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Refuser</button>
            </template>
        </standard-modal>
        <standard-modal v-if="listingPhotoClicked != null" :closeLabel="'Retour'" @close="listingPhotoClicked = null">
            <template v-slot:body>
                <div style="width:100vh;height: 100vh">
                   <div class="row p-2 d-flex">
                        <object style="background-image: url(listingPhotoClicked.preview);" class="col-12" :data="listingPhotoClicked"></object>
                    </div>
                </div>
            </template>
        </standard-modal>
    </main-layout>
</template>

<style scoped>

.photo-div {

    width: 250px;
}
.photo-div:hover {

    cursor:pointer;
    filter: brightness(85%);
    transition: 0.2s;
}

</style>
