<script type="text/ecmascript-6">

import { mapActions } from 'vuex'
import OutstandingAlert from '@/components/OutstandingAlert.vue'

export default {
    components: { OutstandingAlert },
    methods: {
        ...mapActions({
                signOut: 'logout',
            }),
        async logout() {

            await this.signOut()
            this.$router.go()
        },
        isActive(navItem) {

            return this.$route.name == navItem
        },
        switchResellerId(resellerId) {

            this.$store.commit('switchResellerId', resellerId)
            this.$router.replace({name: 'offerTemplates'}).catch(()=>{});
        }
    },
    computed: {

        actualReseller() {

            return this.$store.getters.reseller
        },
        resellers() {
            
            return this.$store.getters.resellers
        }
    }
}
</script>
<template>
<section>
    <outstanding-alert />
    <header class="navbar navbar-expand-lg navbar-light box-shadow-sm">
        <div class="container">
            <!-- Brand -->
            <a class="navbar-brand d-none d-lg-block mr-3 order-lg-1" href="/" style="min-width: 7rem;">
                <img width="142" src="/assets/img/logo-greendid.webp" alt="Greendid"/>
            </a>
            <a class="navbar-brand d-lg-none mr-2 order-lg-1" href="/" style="min-width: 2.125rem;">
                <img width="34" src="/assets/img/logo-greendid.webp" alt="Greendid"/>
            </a>

            <!-- Toolbar -->
            <div v-if="$store.getters.user != undefined" class="navbar-toolbar d-flex align-items-center order-lg-3">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-tool dropdown ml-1 ml-lg-0 mr-n1 mr-lg-2" href="#">
                    <a href="#" class="navbar-tool-icon-box dropdown-toggle"><i class="navbar-tool-icon czi-user"></i></a>
                    <a class="navbar-tool-text ml-n2"><small>{{$store.getters.user.first_name}} | {{actualReseller.name}}</small>Mon compte</a>
                    
                   
                    
                    <div class="dropdown-menu dropdown-menu-right" style="width: 20rem;">
                        <p :class="{'d-none' : reseller.id === $store.getters.resellerId}" class="dropdown-item pointer m-0" v-on:click="switchResellerId(reseller.id)" v-bind:key="index" v-for="(reseller, index) in $store.getters.resellers">Changer pour {{reseller.name}}</p>
                        <router-link class="dropdown-item" :to="{name: 'settings'}">Paramètres</router-link>
                        <a class="dropdown-item" href="#" v-on:click="logout">Se déconnecter</a>
                    </div>
                </div>
            </div>

            <!-- Collapsible menu -->
            <div class="collapse navbar-collapse mr-auto order-lg-2" id="navbarCollapse">
                <hr class="d-lg-none mt-3 mb-2">
                <ul class="navbar-nav">
                    <li class="nav-item" :class="{active: isActive('offerTemplates') || isActive('offerTemplates') || isActive('offerTemplateDetail') }"><router-link class="nav-link" :to="{name:'offerTemplates'}">Offres par références</router-link></li>
                </ul>
                 <ul class="navbar-nav">
                    <li class="nav-item" :class="{active: isActive('offersSpecs') || isActive('specs') || isActive('testSpecs')}"><router-link class="nav-link" :to="{name:'offersSpecs'}">Offres par spécifications</router-link></li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item" :class="{active: isActive('orders')}"><router-link class="nav-link" :to="{name:'orders'}">Commandes</router-link></li>
                </ul>
                <!-- TODEALAFTER -->
                <ul v-if="actualReseller.can_handle_async === 1" class="navbar-nav"> 
                    <li class="nav-item" :class="{active: isActive('listings')}"><router-link class="nav-link" :to="{name:'listings'}">Annonces</router-link></li>
                </ul>
            </div>
        </div>
    </header>
</section>
</template>

<style>

.pointer {

    cursor:pointer;
}

</style>
