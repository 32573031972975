<script type="text/ecmascript-6">
    import offerService from '../services/offer'
    
    export default {
        props: ['value', 'offers', 'isOfferPriceUpdateLoading', 'displayEyeRow'],
        data() {

            return {

                modifiedOffers: {},
                buyBoxes : [],
                offersToUpdate: [],
                openUpdateMultiplePrices:false
            }
        },
        computed: {
            currentPage: {
                get () {
                    return this.value
                },
                set (newPage) {
                    
                    this.$emit('input', newPage)
                }
            },
        },
        methods: {

            updatePrice(index) {

                this.changeOfferTotalPrice(this.offers[index], false)

                if (this.offers[index].price === null) {

                    this.$emit('createPrice', index)
                }
                else {

                    this.$emit('updatePrice', index)
                }
            },
            handleUpdatePrices() {

                this.$emit('offersToUpdate', this.offersToUpdate)
                this.$emit('openUpdateMultiplePrices', true)

            },
            alignPrice(index) {

                this.$emit('alignPriceClicked', index)
                this.$emit('buyBoxClicked', parseFloat(this.offers[index].be_best_offer_total_price))
            },
            areAllBestOffersOK(offer) {

                return offer.nbr_product_offers == offer.nbr_best_offers_ok
            },
            offerClicked(offer) {

                this.$emit('offerClicked', offer)
            },
            getBuyBoxClass(offer) {

                return {

                    'text-success': offer.nbr_best_offers_ok > 0,
                    'text-danger': offer.nbr_best_offers_ok <= 0
                }
            },
            canAlign(offer) {

                return offer.total_price < offer.be_best_offer_total_price
            },
            changeOfferTotalPrice(offer, val) {

                this.$set(this.modifiedOffers, offer.id, val)

                const index = this.offersToUpdate.findIndex(item => item.id === offer.id);

                if (index !== -1 && offer.id != undefined) {

                    this.$set(this.offersToUpdate, index, offer);
                } else {

                    this.offersToUpdate.push(offer);
                }
            },
            async getBuyBox() {

                var response = await offerService.getBuyBoxesByOfferTemplateId(this.$route.params.id)

                this.buyBoxes = response.data

                this.buyBoxes.sort((a, b) => parseFloat(b.is_complete) - parseFloat(a.is_complete));
                this.buyBoxes.sort((a, b) => parseFloat(b.is_working) - parseFloat(a.is_working));
            },
            replaceEmptyBuyboxes(completeId, conditionId, workingId, index) {

                if (this.buyBoxes.find(buybox => buybox.is_complete === completeId && buybox.product_condition_id === conditionId && buybox.is_working === workingId)) {

                    var buybox = this.buyBoxes.find(buybox => buybox.is_complete === completeId && buybox.product_condition_id === conditionId && buybox.is_working === workingId)
                    
                    this.offers[index]['be_best_offer_total_price'] = buybox.be_best_offer_total_price

                    return parseFloat(this.offers[index]['be_best_offer_total_price'])
                }

                return 0
            }
        },
        created() {

            this.getBuyBox()
        }
    }
</script>
<template>
    <section v-if="offers != null">
        <table class="table table-hover font-size-sm">
            <thead>
                <th v-if="displayEyeRow"></th>
                <th class="font-size-xs">Fonctionnel</th>
                <th class="font-size-xs">Etat</th>
                <th class="font-size-xs">Complet</th>
                <th v-if="$store.getters.isAdmin">Buybox</th>
                <th>Prix d'achat</th>
                <th>Com.</th>
                <th>Log.</th>
                <th>Coût complet</th>
                <th v-if="$store.getters.isAdmin">
                    <button :disabled="offersToUpdate.length < 1" v-if="$store.getters.isAdmin" class="btn btn-info m-1" @click="handleUpdatePrices()">
                        <span v-if="isOfferPriceUpdateLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <span v-if="!isOfferPriceUpdateLoading">Valider plusieurs prix</span>
                    </button>
                </th>

            </thead>
            <tbody>
                <tr  class="bg-white" v-for="(offer, index) in offers" :key="offer.id">
                    <td v-if="displayEyeRow" class="align-middle text-center" @click="offerClicked(offer)"><i role="button" class="czi-eye"></i></td>
                    <td class="align-middle text-center">
                        <i v-if="offer.is_working" class="text-success czi-check" />
                        <i v-if="!offer.is_working" class="text-danger czi-close" />
                    </td>
                    <td class="align-middle font-size-xs">{{ offer.condition_name }}</td>
                    <td class="align-middle text-center">
                        <i v-if="offer.is_complete === 1" class="czi-check" />
                        <span class="font-size-xs" v-if="offer.is_complete === 3">N/A</span>
                    </td>
                    <td v-if="$store.getters.isAdmin" class="align-middle text-right" :class="getBuyBoxClass(offer)">
                        <span v-if="offer.be_best_offer_total_price && offer.nbr_best_offers_ok <= 0">{{$n(offer.be_best_offer_total_price, 'currency') }}</span>
                        <span v-else-if="offer.be_best_offer_total_price && offer.nbr_best_offers_ok > 0">{{$n(offer.total_price, 'currency') }}</span>
                        <span v-else>
                            {{ $n(replaceEmptyBuyboxes(offer.is_complete, offer.product_condition_id, offer.is_working, index), 'currency') }}
                        </span>
                        <span v-if="isOfferPriceUpdateLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </td>
                    <td class="align-middle text-right">
                        <span v-if="offer.price === null">
                            -
                        </span>
                        <span v-else >
                            {{ $n(offer.price, 'currency') }}
                        </span> 
                            
                    </td>
                    <td class="align-middle text-right">
                        <span v-if="offer.price === null">
                            -
                        </span>
                        <span v-else >
                            {{ $n(offer.commission_excl_tax_price, 'currency') }}
                        </span> 
                    </td>
                    <td class="align-middle text-right">
                        <span v-if="offer.price === null">
                            -
                        </span>
                        <span v-else >
                            {{ $n(offer.logistic_excl_tax_price, 'currency') }}
                        </span> 
                    </td>
                    <td class="align-middle">
                        <input :disabled="!$store.getters.isAdmin" @change="changeOfferTotalPrice(offer, true)" style="width:80px" class="form-control text-right" :class="{invalid: offer.isError}" type="text" v-model="offer.total_price">
                        <div v-if="offer.isError" class="invalid-feedback" style="display:block">Le prix est trop bas.</div>
                    </td>
                    <td v-if="$store.getters.isAdmin" class="align-middle">
                        <button v-if="$store.getters.isAdmin && canAlign(offer)" class="btn btn-success m-1" @click="alignPrice(index)">
                            <span v-if="isOfferPriceUpdateLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            <span v-if="!isOfferPriceUpdateLoading">Aligner</span>
                        </button>
                        <button v-if="$store.getters.isAdmin" :disabled="modifiedOffers[offer.id] == null" class="btn btn-info m-1" @click="updatePrice(index)">
                            <span v-if="isOfferPriceUpdateLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            <span v-if="!isOfferPriceUpdateLoading">Valider</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>