<script type="text/ecmascript-6">
import tabsMixin from '../mixins/tabs'

export default {
    props: ['value', 'page'],
    mixins: [tabsMixin],
    methods: {
        toggleTab(value, tabId) {

            this.$emit('tabToggled', {tabs : value, tabId: tabId})
        },
        getTo(tab) {

            if (tab.route !== undefined) {

                return tab.route
            }

            return '#' + tab.id
        }
    },
    computed: {

        hashBang() {

            return this.$route.hash
        }
    },
    watch: {

        hashBang(newValue) {

            this.toggleTab(this.value, newValue.replace('#', ''))
        }
    }
}
</script>
<template>
    <ul class="nav nav-tabs">
        <li v-for="tab in value" :key="tab.id" class="nav-item">

            <router-link v-if="page === 'ShowListing'" :to="{name: 'listings', params:{tab: tab.id}}" @click="toggleTab(value, tab.id)" :class="{active: isTabActive(value, tab.id), btn: tab.isButton, 'btn-success': tab.isButton}" class="nav-link" role="tab">{{tab.label}}</router-link>

            <router-link v-else :to="getTo(tab)" @click="toggleTab(value, tab.id)" :class="{active: isTabActive(value, tab.id), btn: tab.isButton, 'btn-success': tab.isButton}" class="nav-link" role="tab">{{tab.label}}</router-link>
        </li>
    </ul>
</template>
