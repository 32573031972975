<script type="text/ecmascript-6">
    import StandardModal from '@/components/StandardModal.vue'

    export default {
        props: ['products'],
        components: {StandardModal},
        methods: {
            close() {

                this.$emit('close')
            }
        }
    }
</script>
<template>
    <standard-modal :title="'Liste des produits'" @close="close">
        <template v-slot:body>
            <ul v-for="product in products" :key="product.id" style="list-style-type: none;">
                <li>{{product.name}}</li>
            </ul>
        </template>
    </standard-modal>
</template>
