import axios from 'axios'
import filtersHelper from './helpers/filters'
import requestMaker from './helpers/requestMaker'


export default {

    list(page, filters, doPagination = true) {

        let path = '/api/reseller/offers'
        let body = filtersHelper.getBodyFilters(page, filters)

        if (doPagination == false) {

            body.paginate = 0
        }

        return requestMaker.axiosRequest(path, 'POST',body, {'X-HTTP-Method-Override': 'GET'})
    },
    getFilters(withSpecs = false) {

        let path = '/api/reseller/offers/filters'
        if (withSpecs) {

            path = path + '?withSpecs=1'
        }
        return requestMaker.axiosRequest(path)
    },
    updatePrice(offers) {

        let path = '/api/reseller/offers'
        let data = {
            'offers': offers
        }

        return requestMaker.axiosRequest(path, 'PATCH', data);
    },
    countBuyBox(filters, isBest) {

        let filtersCopied = {}
        Object.assign(filtersCopied, filters)
        filtersCopied['paginate'] = 1
        filtersCopied['isBestOffer'] = isBest
        
        let path = '/api/reseller/offers'
        let body = filtersHelper.getBodyFilters(1, filtersCopied)

        return requestMaker.axiosRequest(path, 'POST', body, {'X-HTTP-Method-Override': 'GET'});
    },
    get(offerId) {

        let path = '/api/reseller/offers/' + offerId

        return requestMaker.axiosRequest(path)
    },
    simulatePrice(totalPrice, weight, deliveryType, merchantId) {

        let path = '/api/reseller/prices'
        path = path + '?totalPrice=' + totalPrice
        path = path + '&weight=' + weight   
        path = path + '&deliveryType=' + deliveryType
        path = path + '&merchantId=' + merchantId

        return requestMaker.axiosRequest(path, 'POST', {}, {'X-HTTP-Method-Override': 'GET'});
    },
    listAllOfferTemplates(page, filters) {

        let path = '/api/reseller/offers/offerTemplates?page=' + page 
        let body = filtersHelper.getBodyFilters(page, filters)
        
        return requestMaker.axiosRequest(path, 'POST', body, {'X-HTTP-Method-Override': 'GET'})
    },
    getProductCategoryFilters() {

        let path = '/api/reseller/offers/category'

        return axios.get(path);
    },
    getProductBrandFilters() {

        let path = '/api/reseller/offers/brand'
      
        return axios.get(path);
    },
    countOfferTemplatesByPrice(filters) {

        let path = '/api/reseller/offers/offerTemplates/count'
        let body = filters

        return requestMaker.axiosRequest(path, 'POST', body, {'X-HTTP-Method-Override': 'GET'})
    },
    createPrice(formData) {

        let path = '/api/reseller/offers/' + formData.offerTemplateId
        let body = formData

        return requestMaker.axiosRequest(path, 'POST', body)
    },
    getOfferTemplate(id, withProducts = false) {

        withProducts = withProducts ? 1 : 0
        let path = '/api/reseller/offers/offerTemplate/' + id + '?wp=' + withProducts

        return axios.get(path)
    },
    getBuyBoxesByOfferTemplateId(id) {

        let path = '/api/reseller/offers/offerTemplates/' + id + '/buyboxes'

        return axios.get(path)
    }
}
