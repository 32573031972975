import axios from 'axios'
import filtersHelper from './helpers/filters'
import requestMaker from './helpers/requestMaker'
import store from '../store/index'

export default {

    list(page, filters = {}) {

        filters['paginate'] = 50
        filters['resellerId'] = store.getters.resellerId
        let path = '/api/reseller/orders'
        let uri = filtersHelper.generateURIFilters(path, page, filters)

        return axios.get(uri);
    },
    accept(orderPublicId) {

        let path = '/api/reseller/orders/' + orderPublicId + '/accept'

        return requestMaker.axiosRequest(path, 'PATCH')
    },
    refuse(orderPublicId, reason, files) {

        var formData = new FormData();
        let photo2 = files.length >= 2 ? files[1].file : null
        let photo3 = files.length >= 3 ? files[2].file : null
        let photo4 = files.length >= 4 ? files[3].file : null
        let photo5 = files.length >= 5 ? files[4].file : null
        let photo6 = files.length === 6 ? files[5].file : null

        let path = '/api/reseller/orders/' + orderPublicId + '/refuse'

        formData.append('reason', reason)
        formData.append('photo1', files[0].file)
        formData.append('resellerId', store.getters.resellerId)

        if (photo2) {

            formData.append('photo2', photo2)
        }

        if (photo3) {

            formData.append('photo3', photo3)
        }

        if (photo4) {

            formData.append('photo4', photo4)
        }

        if (photo5) {

            formData.append('photo5', photo5)
        }

        if (photo6) {

            formData.append('photo6', photo6)
        }

        return axios.post(path, formData, {headers: {
            'Content-Type': 'multipart/form-data',
            'X-HTTP-Method-Override': 'PATCH'
         }})
    },
    counterOffer(orderPublicId, reason, price, files) {

        var formData = new FormData();
        let photo2 = files.length >= 2 ? files[1].file : null
        let photo3 = files.length >= 3 ? files[2].file : null
        let photo4 = files.length >= 4 ? files[3].file : null
        let photo5 = files.length >= 5 ? files[4].file : null
        let photo6 = files.length === 6 ? files[5].file : null

        let path = '/api/reseller/orders/' + orderPublicId + '/counterOffer'

        formData.append('reason', reason)
        formData.append('totalPrice', parseInt(price * 100))
        formData.append('photo1', files[0].file)
        formData.append('resellerId', store.getters.resellerId)

        if (photo2) {

            formData.append('photo2', photo2)
        }

        if (photo3) {

            formData.append('photo3', photo3)
        }

        if (photo4) {

            formData.append('photo4', photo4)
        }

        if (photo5) {

            formData.append('photo5', photo5)
        }

        if (photo6) {

            formData.append('photo6', photo6)
        }

        return axios.post(path, formData, {headers: {
            'Content-Type': 'multipart/form-data'
         }})
    },
    getMerchantInvoice(orderPublicId) {

        let path = '/api/reseller/orders/' + orderPublicId + '/merchantInvoice'

        return requestMaker.axiosRequest(path)
    },
    getTransfer(orderPublicId) {

        let path = '/api/reseller/orders/' + orderPublicId + '/transfer'

        return requestMaker.axiosRequest(path, 'POST', {}, {'X-HTTP-Method-Override': 'GET'})
    },
    standBy(orderPublicId, reason) {

        let path = '/api/reseller/orders/' + orderPublicId + '/standby'
        let data = {reason: reason}

        return requestMaker.axiosRequest(path, 'PATCH', data)
    },
    getUserInfos(orderPublicId) {

        let path = '/api/reseller/orders/' + orderPublicId + '/userInfos'

        return requestMaker.axiosRequest(path)
    },
    getKyc(orderPublicId) {

        let path = '/api/reseller/orders/' + orderPublicId + '/kyc'

        return requestMaker.axiosRequest(path, 'GET', {}, {responseType: 'blob'})
    },
    deliveryAcceptedOnSite(orderPublicId) {

        let path = '/api/reseller/orders/' + orderPublicId + '/deliveryAcceptedOnSite'

        return requestMaker.axiosRequest(path, 'PATCH')
    },
    getUserKycDetails(userId) {

        let path = '/api/reseller/orders/user/' + userId + '/kyc'

        return axios.get(path)
    },
    getProductAttributes(orderPublicId) {

        let path = '/api/reseller/orders/' + orderPublicId + '/productAttributes'

        return axios.get(path)
    },
    countOrders(orderStatuses, resellerId, trackingNumber = null) {

        let path = '/api/reseller/orders/count'

        let body = {
            orderStatuses : orderStatuses,
            trackingNumber : trackingNumber,
            resellerId: resellerId
        }

        return requestMaker.axiosRequest(path, 'PATCH', body)
    },
}
