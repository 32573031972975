<script type="text/ecmascript-6">
    import Paginate from 'vuejs-paginate'
    
    export default {
        props: {
            value: Number, 
            offers: Object, 
            isOfferPriceUpdateLoading: Boolean
        },
        components: { Paginate },
        computed: {
            currentPage: {
                get () {
                    return this.value
                },
                set (newPage) {
                    
                    this.$emit('input', newPage)
                }
            }
        },
        methods: {

            updatePrice(index) {

                this.$emit('updatePrice', index)
            },
            areAllBestOffersOK(offer) {

                return offer.nbr_product_offers == offer.nbr_best_offers_ok
            },
            offerClicked(offer) {

                this.$emit('offerClicked', offer)
            },
            getBuyBoxClass(offer) {

                return {

                    'text-success': offer.nbr_best_offers_ok > 0 && offer.nbr_product_offers == offer.nbr_best_offers_ok,
                    'text-danger': offer.nbr_best_offers_ok <= 0,
                    'text-warning': offer.nbr_best_offers_ok > 0 && offer.nbr_product_offers != offer.nbr_best_offers_ok
                }
            }
        }
    }
</script>
<template>
    <section v-if="offers != null">
        <table class="table table-hover font-size-sm">
            <thead>
                <th></th>
                <th>Libellé</th>
                <th>Marque</th>
                <th>Diag / écran</th>
                <th>Proc.</th>
                <th>Gen. proc.</th>
                <th>RAM</th>
                <th>DD</th>
                <th>GPU</th>
                <th class="font-size-xs">Etat</th>
                <th class="font-size-xs">Fonctionnel</th>
                <th>Buybox</th>
                <th>Prix d'achat</th>
                <th>Com.</th>
                <th>Log.</th>
                <th>Coût complet</th>
            </thead>
            <tbody>
                <tr class="bg-white" v-for="offer in offers.data" :key="offer.id">
                    <td class="align-middle text-center" @click="offerClicked(offer)"><i role="button" class="czi-eye"></i></td>
                    <td class="align-middle">{{ offer.reseller_product_label }}</td>
                    <td>{{ offer.brand_name }}</td>
                    <td>{{ offer.attr_diagonal != undefined ? offer.attr_diagonal : offer.attr_display_type}}</td>
                    <td>{{ offer.attr_processor_family }}</td>
                    <td>{{ offer.attr_processor_generation }}</td>
                    <td>{{ offer.attr_ram }}</td>
                    <td>{{ offer.attr_hard_drive }}</td>
                    <td>{{ offer.attr_gpu }}</td>
                    <td class="align-middle font-size-xs">{{ offer.condition_name }}</td>
                    <td class="align-middle text-center">
                        <i v-if="offer.is_working" class="czi-check" />
                    </td>
                    <td class="align-middle text-right" :class="getBuyBoxClass(offer)">{{$n(offer.be_best_offer_total_price, 'currency') }}</td>
                    <td class="align-middle text-right">{{ $n(offer.price, 'currency') }}</td>
                    <td class="align-middle text-right">{{ $n(offer.commission_excl_tax_price, 'currency') }}</td>
                    <td class="align-middle text-right">{{ $n(offer.logistic_excl_tax_price, 'currency') }}</td>
                    <td class="align-middle text-right">
                        <span>{{ $n(offer.total_price, 'currency') }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="pagination">
            <paginate v-if="offers.last_page != null"
                v-model="currentPage"
                :pageCount="offers.last_page"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :prevText="'<i class=\'czi-arrow-left\'></i> Précédent'"
                :nextText="'Suivant <i class=\'czi-arrow-right\'></i>'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :nextClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextLinkClass="'page-link'"
            />
        </nav>
    </section>
</template>
