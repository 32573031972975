<script type="text/ecmascript-6">

import offerService from '../services/offer'
import MainLayout from '../layouts/Main.vue'
import Paginate from 'vuejs-paginate'

export default {
    components: { MainLayout, Paginate},
    mixins: [],
    data() {
        
        return {
            offerTemplates: {},
            currentPage: 1,
            lastPage: null,
            filters: {
                categoryId: (localStorage.categoryId != undefined) ? localStorage.categoryId : '',
                displayedBrand: (localStorage.displayedBrand != undefined) ? localStorage.displayedBrand : '',
                label: (localStorage.label != undefined) ? localStorage.label : '',
                hasPrice: (localStorage.hasPrice != undefined) ? localStorage.hasPrice : '',
                resellerId: this.$store.state.resellerId  ? this.$store.state.resellerId : null
            },
            newFilters: {
                categoryId: (localStorage.categoryId != undefined) ? localStorage.categoryId : '',
                displayedBrand: (localStorage.displayedBrand != undefined) ? localStorage.displayedBrand : '',
                label: (localStorage.label != undefined) ? localStorage.label : '',
                hasPrice: (localStorage.hasPrice != undefined) ? localStorage.hasPrice : '',
                resellerId: this.$store.state.resellerId ? this.$store.state.resellerId : null
            },
            categories: [],
            brands: [],
            countWithPrice: 0,
            countWithoutPrice: 0,
            isApplyFiltersLoading: false,
            isResetFiltersLoading: false,
            offerTemplateTab: true,
            offersTab: false
        }
    },
    methods: {

        async loadOfferTemplates(resetPage) {

            if (resetPage) {

                this.isResetFiltersLoading = true
            } 
            else {

                this.isApplyFiltersLoading = true
            }
            
            let response = await offerService.listAllOfferTemplates(this.currentPage, this.filters)
            this.offerTemplates = response.data.data
            this.lastPage = response.data.last_page
            this.isApplyFiltersLoading = false
            this.isResetFiltersLoading = false
        },
        async loadProductCategoryFilters() {

            let response = await offerService.getProductCategoryFilters()
            this.categories = response.data
        },
        async loadCountOfferTemplates() {
            
            let saveFilterPrice = this.filters.hasPrice 
        
            this.filters.hasPrice = 1
            offerService.countOfferTemplatesByPrice(this.filters, this.filters.hasPrice)
                .then((response)=> {

                    this.countWithPrice = response.data
                })

            this.filters.hasPrice = 0
            offerService.countOfferTemplatesByPrice(this.filters, this.filters.hasPrice)
                .then((response)=> {

                    this.countWithoutPrice = response.data
                })
            
            this.filters.hasPrice = saveFilterPrice
        }, 
        async loadProductBrandFilters() {

            let response = await offerService.getProductBrandFilters()
            this.brands = response.data
        },
        loadOfferTemplatesByType(type) {

            if (type === '') {

                this.newFilters.hasPrice = ''
            } else if (type === 1) {
                
                this.newFilters.hasPrice = 1
            } else if (type === 0) {

                this.newFilters.hasPrice = 0
            }
            this.applyFilters(false)
        },
        applyFilters(resetPage) {

            Object.assign(this.filters, this.newFilters)
            
            if (!resetPage) {

                this.currentPage = 1
            }

            localStorage.categoryId = this.filters.categoryId
            localStorage.displayedBrand = this.filters.displayedBrand
            localStorage.label = this.filters.label
            localStorage.hasPrice = this.filters.hasPrice
            localStorage.resellerId = this.filters.resellerId

            this.loadOfferTemplates(resetPage)
            this.loadCountOfferTemplates()
        },
        resetFilters() {

            this.newFilters = {
                categoryId: '',
                displayedBrand: '',
                label: '',
                hasPrice: '',
                resellerId: this.$store.state.resellerId
            }
            localStorage.clear()
            this.applyFilters(true)
        },
        tableRowLink(offerTemplate) {

            this.$router.push({name: 'offerTemplateDetail', params:{id:offerTemplate.id, sku:offerTemplate.label, hasConditionId: offerTemplate.category.has_condition, hasCompleteId: offerTemplate.category.has_complete}})
        }
    },
    computed: {
        
        allCount() {

            return this.countWithPrice + this.countWithoutPrice
        },
        
        resellerId() {

            return this.$store.state.resellerId
        },

    },
    watch: {

        resellerId(val) {

            this.filters.resellerId = val
            this.newFilters.resellerId = val
            this.loadOfferTemplates()
            this.loadCountOfferTemplates()
        },
        offerTemplates(val) {

            this.offerTemplates = val
        },
        countWithPrice(val) {

            this.countWithPrice = val
        },
        countWithoutPrice(val) {

            this.countWithoutPrice = val
        },
    },
    created() {

        this.loadOfferTemplates()
        this.loadCountOfferTemplates()
        this.loadProductCategoryFilters()
        this.loadProductBrandFilters()
        
    }, 
}
</script>

<template>
    <main-layout>
        <h3>Liste de toutes les offres Greendid</h3>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <router-link  :to="{name:'offerTemplates'}" :class="{active : offerTemplateTab}" class="nav-link" role="tab">
                    Toutes les offres
                </router-link>
            </li>
        </ul>
        <div class="row mx-n2 pt-2">
            <div class="col-md-4 col-sm-6 px-2 mb-4">
                <a href="#" role="tab" @click="loadOfferTemplatesByType('')">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{ 'border border-primary': newFilters.hasPrice === '' }">
                        <h3 class="font-size-sm text-muted">Toutes les offres</h3>
                        <p class="h2 mb-2 text-success">{{ allCount }}</p>
                        <span v-if="isResetFiltersLoading || isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 px-2 mb-4">
                <a href="#" role="tab" @click="loadOfferTemplatesByType(1)">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{ 'border border-primary': newFilters.hasPrice === 1 }">
                        <h3 class="font-size-sm text-muted">Avec prix</h3>
                        <p class="h2 mb-2 text-success">{{ countWithPrice }}</p>
                        <span v-if="isResetFiltersLoading || isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 px-2 mb-4">
                <a href="#" role="tab" @click="loadOfferTemplatesByType(0)">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{ 'border border-primary': newFilters.hasPrice === 0 }">
                        <h3 class="font-size-sm text-muted">Sans prix</h3>
                        <p class="h2 mb-2 text-danger">{{ countWithoutPrice }}</p>
                        <span v-if="isResetFiltersLoading || isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </div>
                </a>
            </div>
        </div>
        <form v-on:submit.prevent>
            <div class="row">
                <div class="col-8">
                    <div class="row">
                        <div class="col form-group">
                            <input class="cz-filter-search form-control" v-model="newFilters.label" type="text" placeholder="Libéllé">
                        </div>
                        <div class="col form-group">
                            <select v-model="newFilters.displayedBrand" class="cz-filter-search form-control">
                                <option value="" disabled selected>Marque</option>
                                <option v-bind:key="brand.id" :value="brand.name" v-for="brand in brands">
                                    {{ brand.name }}
                                </option>
                        </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <select v-model="newFilters.categoryId" class="cz-filter-search form-control">
                                <option value="" disabled selected>Catégorie</option>
                                <option v-bind:key="category.id" :value="category.id" v-for="category in categories"  placeholder="Catégorie">
                                    {{ category.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 form-group">
                            <select v-model="newFilters.hasPrice" class="cz-filter-search form-control">
                                <option value="">Avec/Sans Prix</option>
                                <option value=1>Avec prix</option>
                                <option value=0>Sans prix</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col form-group">
                            <button @click="applyFilters()" class="btn btn-success w-100" type="submit" :disabled="isApplyFiltersLoading"><span v-if="isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Appliquer les filtres</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <button @click="resetFilters()" class="btn btn-primary w-100" type="submit" :disabled="isResetFiltersLoading"><span v-if="isResetFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Effacer les filtres</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <nav class="mb-3" aria-label="pagination" @click="loadOfferTemplates()">
            <paginate v-if="lastPage != null"
                v-model="currentPage"
                :pageCount="lastPage"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :prevText="'<i class=\'czi-arrow-left\'></i> Précédent'"
                :nextText="'Suivant <i class=\'czi-arrow-right\'></i>'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :nextClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextLinkClass="'page-link'"
            />
        </nav>
        <table class="table table-hover font-size-sm bg-white">
            <thead>
                <th>Libellé</th>
                <th>Marque</th>
                <th>Catégorie</th>
                <th>Au moins un prix</th>
                <th>Buybox</th>
            </thead>
            <tbody>
                <tr @click="tableRowLink(offerTemplate)" class="row-hover-clickable" v-for="offerTemplate in offerTemplates" :key="offerTemplate.id">
                    <td class="align-middle">{{ offerTemplate.label }}</td>
                    <td class="align-middle">{{ offerTemplate.displayed_brand }}</td>
                    <td class="align-middle">{{ offerTemplate.category.name }} </td>
                    <td class="align-middle text-success" v-if="offerTemplate.has_price > 0">OUI</td>
                    <td class="align-middle text-danger" v-else>NON</td>
                    <td class="align-middle text-danger d-flex"> 
                        <span :class="{ 'text-danger': offerTemplate.nbr_reseller_buybox === 0 }" class="text-success">
                            {{ offerTemplate.nbr_reseller_buybox }} 
                        </span>
                        <span :class="{ 'text-success': offerTemplate.nbr_reseller_buybox === offerTemplate.total_offers_possible }" >
                             /{{ offerTemplate.total_offers_possible }}
                        </span> 
                    </td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="pagination" @click="loadOfferTemplates()">
            <paginate v-if="lastPage != null"
                v-model="currentPage"
                :pageCount="lastPage"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :prevText="'<i class=\'czi-arrow-left\'></i> Précédent'"
                :nextText="'Suivant <i class=\'czi-arrow-right\'></i>'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :nextClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextLinkClass="'page-link'"
            />
        </nav>
    </main-layout>
</template>

<style scoped>

.row-hover-clickable:hover{

    cursor: pointer;
    z-index: 1000;
}

</style>