<script type="text/ecmascript-6">
export default {
    props: ['order'],
    computed: {
        buttonClass: function() {
            return {
                'badge-warning': [4, 7].includes(this.orderStatusId),
                'badge-success': [51, 52].includes(this.orderStatusId),
                'badge-secondary': [1, 2, 3, 6, 9].includes(this.orderStatusId)
            }
        },
        statusInfo() {

            return this.$t('STATUS_INFO.STATUS_' + this.orderStatusId)
        },
        statusLabel() {

            return this.$t('STATUS.STATUS_' + this.orderStatusId)
        },
        orderStatusId() {

            let orderStatusId
            switch (this.order.order_status_id) {

                case 5:
                    orderStatusId = this.order.invoice_fees_id != undefined && this.order.invoice_coupons_id != undefined ? 52 : 51
                    break;
                default:
                    orderStatusId = this.order.order_status_id
            }

            return orderStatusId
        }
    }
}
</script>
<style>
.tooltip {
    display: block !important;
    z-index: 10000;
}

.tooltip .tooltip-inner {
    background: #F2F5FB;
    color: #4b566b;
    border-radius: 16px;
    padding: 5px 10px 4px;
    font-family: "Rubik",sans-serif;
}

.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #F2F5FB;
    z-index: 1;
}

.tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
    margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
    margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip[x-placement^="left"] {
    margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
}
</style>
<template>
    <span class="badge" v-tooltip="statusInfo" :class="buttonClass" v-html="statusLabel"></span>
</template>
