<script type="text/ecmascript-6">
    import ListingStatus from '../components/ListingStatus.vue'

    export default {
        props: ['listings', 'brands', 'categories', 'filter'],
        components: {ListingStatus},
        methods: {
            brandName(brandId) {

                return this.brands.filter(brand => brand.id === brandId)[0].name
            },
            categoryName(categoryId) {

                return this.categories.filter(category => category.id === categoryId)[0].name
            },
        },
        computed: {

        }
    }
</script>
<template>
    <table class="table table-hover font-size-sm bg-white">
        <thead>
            <tr>
                <th>Modèle</th>
                <th>Marque</th>
                <th>Categorie</th>
                <th>Statut</th>
                <th>Ancienneté</th>
                <th>Prix</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="listing in listings" :key="listing.public_id">
                <td class="align-middle">
                    <router-link class="link" title="Click to open details" :to="{name: 'listing', params: {listingPublicId: listing.public_id}}">
                        {{ listing.model }}
                    </router-link>
                </td>
                <td class="align-middle">{{ brandName(listing.brand_id) }}</td>
                <td class="align-middle">{{ categoryName(listing.category_id) }}</td>
                <td class="align-middle">
                    <listing-status :listing="listing"/>    
                </td>
                <td>{{ listing.created_at | dateDiff }}</td>
                <td><span v-if="listing.status === 'accepted'">{{ listing.total_price / 100 }} €</span></td>
            </tr>
        </tbody>
    </table>
</template>

<style scoped>

.link {

    color: rgb(0, 153, 255);
}

</style>
