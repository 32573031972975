<script type="text/ecmascript-6">

import { uuid } from 'vue-uuid'
export default {

    props: [
        'files'
    ],
    data() {

        return {

            previewImages: [],
            filesData: this.files
        }
    },
    methods: {

        addFile(e) {

            let droppedFiles = e.dataTransfer != undefined ? e.dataTransfer.files : e.target.files
            
            if ((droppedFiles.length + this.filesData.length) > 6) {
                
                alert('Vous ne pouvez pas importer plus de 6 photos')
                
                return
            }
            
            if (!droppedFiles) return

            let totalSize = 0
            this.filesData.forEach(f => {
                
                totalSize += f.file.size / 1024 / 1024
            });

            ([...droppedFiles]).forEach(f => {
                
                let fileSize = f.size / 1024 / 1024
                if ((totalSize + fileSize) > 8) {

                    alert('Impossible d\'ajouter plus de fichiers, la taille total dépasse 8 Mo')

                    return
                }

                let id = uuid.v4()
                this.filesData.push({id: id, file: f})
                totalSize += fileSize
            });
        },
        removeFile(id) {

            this.filesData = this.filesData.filter(file => {
                
                return file.id != id
            })

            this.$emit("updateFiles", this.filesData);
        },
        clickOnInput() {

            this.$refs.fileInput.click();
        },
    },
    watch: {

        filesData() {

            this.previewImages = []
            this.filesData.forEach(file => {

                let reader = new FileReader
                reader.onload = e => {

                    this.previewImages.push({id: file.id, preview: e.target.result})
                }

                reader.readAsDataURL(file.file)
            })
        }
    }
}
</script>

<template>
    <div>
        <input class="d-none" id="fileInput" ref="fileInput" multiple type="file" @change="addFile" accept=".png, .jpg, .jpeg, .pdf">
        <div class="d-flex align-items-center">

            <button :disabled="files.length >= 6" class="btn btn-outline-info mr-2" @click="clickOnInput">Chargez vos photos</button>
            <small>(max : 6)</small>
        </div>
        <div class="row p-2 d-flex">
            <div :key="preview.id" v-for="preview in previewImages" class="photo col-6 col-lg-4 m-1 m-lg-2" :style=" { 'background-image': 'url(' + preview.preview + ')'}">
                <div class="d-flex justify-content-between m-1">
                    <i class="bi bi-camera-fill">
                    </i>
                    <span @click="removeFile(preview.id)" class="bg-white text-danger px-2 delete-btn border">X</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.photo {

    height: 100px;
    border: 1px solid var(--bs-gray-400);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
}

.delete-btn {

    cursor: pointer;
}

</style>