import axios from 'axios'

export default {

    getCSRF() {

        var path = '/sanctum/csrf-cookie'

        return axios.get(path);
    },
    login(email, password) {

        var path = '/api/customer/login'
        var data = {
            email: email,
            password: password,
            app: 'reseller'
        }

        return axios.post(path, data)
    },
    logout() {

        var path = '/api/logout'

        return axios.get(path)
    },
    getCurrentUser() {

        var path = '/api/user'

        return axios.get(path)
    },
    forgotPassword(email) {

        var path = '/api/forgot-password'
        var data = {
            email: email,
            app: 'reseller'
        }

        return axios.post(path, data)
    },
    resetPassword(token, email, password, passwordConfirmation) {

        var path = '/api/reset-password'
        var data = {
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            app: 'reseller'
        }

        return axios.post(path, data)
    }
}
