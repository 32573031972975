<script type="text/ecmascript-6">
    export default {
        props: ['value', 'items', 'label', 'id'],
        computed: {
            itemSelected: {
                get () {
                    return this.value
                },
                set (newItem) {
                    
                    this.$emit('input', newItem)
                }
            },
        }
    }
</script>
<template>
    <div class="widget widget-categories p-1 pl-3" style="background-color:white;border:1px solid #dae1e7;border-radius:.3125rem">
        <div :id="'filters-' + id" class="accordion">
            <div class="card">
                <div class="card-header">
                    <h3 class="accordion-heading">
                        <a :href="'#' + id" class="collapsed d-flex justify-content-between align-items-center" role="button" data-toggle="collapse" aria-expanded="false" :aria-controls="id">
                            {{ label }}
                            <span v-if="value.length > 0" class="badge badge-pill badge-danger">{{ value.length }}</span>
                            <span class="accordion-indicator"></span>
                        </a>
                    </h3>
                </div>
                <div :id="id" class="collapse" :data-parent="'#filters-' + id">
                    <div class="card-body">
                        <div class="widget widget-links cz-filter">
                            <ul class="widget-list cz-filter-list list-unstyled pt-1" style="max-height: 12rem;" data-simplebar data-simplebar-auto-hide="false">
                                <li v-for="item in items" :key="item.id" class="cz-filter-item d-flex justify-content-between align-items-center mb-1 pl-1">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" :id="id + '-'+item.id" :value="item.id" v-model="itemSelected" class="custom-control-input">
                                        <label class="custom-control-label cz-filter-item-text" :for="id + '-'+item.id">{{item.name}}</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>