<script type="text/ecmascript-6">
    import StandardModal from '../components/StandardModal.vue'

    export default {
        props: ['value'],
        components: {StandardModal},
        methods: {
            getBestOfferPrice(offer, product) {

                if (product.best_offer == undefined) return null
                if (product.best_offer.be_best_offer_total_price == undefined) return offer.total_price

                return product.best_offer.be_best_offer_total_price
            },
            getBestOfferClass(product) {

                let hasBestOffer = product.best_offer != undefined && product.best_offer.is_your_best_offer
                return {
                    'text-success': hasBestOffer,
                    'text-danger': !hasBestOffer
                }
            },
            close() {

                this.$emit('close')
            }
        }
    }
</script>
<template>
    <standard-modal v-if="value != undefined" :title="'Liste des produits'" @close="close">
        <template v-slot:body>
            <div>
                Votre offre : <strong>{{$n(value.total_price, 'currency')}}</strong>
            </div>
            <table class="table mt-2">
                <thead>
                    <tr>
                        <th>EAN</th>
                        <th>Produit</th>
                        <th v-if="$store.getters.isAdmin">Buybox</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in value.products" :key="product.id">
                        <td>{{product.ean}}</td>
                        <td>{{product.name}}</td>
                        <td v-if="$store.getters.isAdmin" :class="getBestOfferClass(product)">{{getBestOfferPrice(value, product) != undefined ? $n(getBestOfferPrice(value, product), 'currency') : ''}}</td>
                    </tr>
                </tbody>
            </table>
        </template>
    </standard-modal>
</template>
