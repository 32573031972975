<script type="text/ecmascript-6">

    import OrderStatus from '../components/OrderStatus.vue'
    
    export default {
        props: ['orders', 'hasActions', 'canDownloadFiles', 'currentTab', 'canDownloadInvoice'],
        components: { OrderStatus },
        data() {
            
            return {

                isLoading:false
            }
        },
        methods: {

            accept(order) {

                this.$emit('orderAccepted', order)
            },
            refuse(order) {

                this.$emit('orderRefused', order)
            },
            counterOffer(order) {

                this.$emit('orderCounterOffered', order)
            },
            standBy(order) {

                this.$emit('orderStandBy', order)
            },
            getUserInfos(order) {

                this.$emit('getUserInfos', order)
            },
            clickMerchantInvoice(order) {

                this.$emit('merchantInvoiceClicked', order)
            },
            clickTransfer(order) {

                this.$emit('transferClicked', order)
            },
            clickIdentityProof(order) {

                this.$emit('IdentityProofClicked', order)
            },
            clickOrderPublicId(order) {

                this.$emit('OrderPublicIdClicked', order)
            },
            clickAttributes(order) {

                this.$emit('AttributesClicked', order)
            },
            clickAcceptedOnSite(order) {

                this.isLoading = true
                this.$emit('acceptedOnSite', order)
            },
            canMakeCounterOffer(order) {

                // Darty
                if (order.merchant_id == 9535101279 && order.contribution_amount > 0) {

                    return false
                }

                return true
            },
            getOP(order) {
                // Darty
                if (order.merchant_id == 9535101279 && order.contribution_amount > 0) {

                    return order.merchant.name + ' - ' + 'prix fixe'
                }

                return order.brand_campaign != undefined ? order.brand_campaign.name : ''
            },
            getSourceTypeIconClass(sourceType) {

                if (sourceType == 'store') {

                    return 'czi-store'
                }

                if (sourceType == 'web') {

                    return 'czi-laptop'
                }

                return '';
            }
        },
        computed: {
            ordersSorted() {

                const ordersCopy = [...this.orders];
                
                return this.currentTab === 'ordersDone' ? ordersCopy.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) : this.orders
            }
        }
    }
</script>
<template>
    <table class="table table-hover font-size-sm">
        <thead>
            <tr>
                <th v-if="currentTab === 'ordersToValidate' || currentTab === 'ordersDone'" class="font-size-xs">Statut modifié le </th>
                <th>Tracking</th>
                <th class="font-size-xs">N° de commande</th>
                <th class="font-size-xs">Source</th>
                <th class="font-size-xs">OP</th>
                <th v-if="canDownloadInvoice" class="font-size-xs">Facture d'origine</th>
                <th class="font-size-xs">SKU</th>
                <th>Catégorie</th>
                <th>Marque</th>
                <th>Produit</th>
                <th>Etat</th>
                <th>Prix d'achat vendeur</th>
                <th>Com.</th>
                <th>Log.</th>
                <th>Coût complet</th>
                <th>Status</th>

                <th v-if="canDownloadFiles" class="font-size-xs">Bon de cession</th>
                <th v-if="canDownloadFiles" class="font-size-xs">Pièce d'identité</th>
                <th v-if="$store.getters.reseller.can_accept_delivery_on_site"></th>
                <th v-if="hasActions"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white" v-for="order in ordersSorted" :key="order.public_id">
                <td v-if="currentTab === 'ordersToValidate' || currentTab === 'ordersDone'" class="align-middle">{{ new Date(order.updated_at).toLocaleString('fr-FR',{day: '2-digit', month: '2-digit', year: '2-digit'}) }}</td>
                <td class="align-middle">{{ order.latest_delivery_note ? order.latest_delivery_note.tracking_number : '' }}</td>
                <td class="align-middle font-size-xs"><span @click="clickOrderPublicId(order)" class="text-info link">{{ order.public_id }}</span></td>
                <th class="align-middle text-center font-size-lg"><i :class="getSourceTypeIconClass(order.source_type)" :title="order.source_type" /></th>
                <td class="align-middle font-size-xs"><span class="badge badge-warning">{{ getOP(order) }}</span></td>
                <td v-if="canDownloadInvoice" class="align-middle text-center text-info">
                    <i v-if="order.invoice_filename != null" role="button" class="czi-download" @click="clickMerchantInvoice(order)"></i>
                </td>
                <td class="align-middle font-size-xs">{{ order.reseller_product_sku }}</td>
                <td class="align-middle">{{ order.category.name }}</td>
                <td class="align-middle">{{ order.brand_name }}</td>
                <td class="align-middle">
                    <span class="text-info link" @click="clickAttributes(order)" v-if="order.attached_to == 'specs' || order.attached_to == 'product'">{{ order.label }}</span>
                    <router-link target="_blank" class="text-info link" v-else-if="order.attached_to == 'listing'" :to="{name:'listing', params: {listingPublicId: order.listing.public_id}}">{{ order.label }}</router-link>
                    <span v-else>{{ order.label }}</span>
                </td>
                <td class="align-middle font-size-xs text-nowrap">
                    <strong>{{order.condition.name}}</strong>
                    <br/>
                    <span class="text-success" v-if="order.is_product_working">FONCTIONNEL</span>
                    <span class="text-danger" v-else>NON FONCTIONNEL</span>
                    <br/>
                    <span class="text-success" v-if="order.is_product_complete == 1">COMPLET</span>
                    <span class="text-danger" v-if="order.is_product_complete == 0">NON COMPLET</span>
                    <br v-if="order.is_battery_ok != undefined"/>
                    <span class="text-success" v-if="order.is_battery_ok != undefined && order.is_battery_ok == true">BATTERIE OK</span>
                    <span class="text-danger" v-if="order.is_battery_ok != undefined && order.is_battery_ok == false">BATTERIE KO</span>
                </td>
                <td class="align-middle text-right">{{ $n(order.price, 'currency') }}</td>
                <td class="align-middle text-right">{{ $n(order.commission_excl_tax_price, 'currency') }}</td>
                <td class="align-middle text-right">{{ $n(order.logistic_excl_tax_price, 'currency') }}</td>
                <td class="align-middle text-right">{{ $n(order.total_price, 'currency') }}</td>
                <td class="align-middle text-center">
                    <order-status :order="order" />
                </td>

                <td v-if="canDownloadFiles" class="align-middle text-center">
                    <i role="button" class="czi-download" @click="clickTransfer(order)"></i>
                </td>
                <td v-if="canDownloadFiles" class="align-middle text-center">
                    <i title="Liste des produits" role="button" class="ml-2 czi-eye" @click="clickIdentityProof(order)"></i>
                </td>
                <td v-if="order.reseller.can_accept_delivery_on_site" class="align-middle text-right text-danger">
                    <button :disabled="isLoading" v-if="$store.getters.reseller.can_accept_delivery_on_site && order.order_status_id === 2" @click="clickAcceptedOnSite(order)" class="btn btn-success mt-2">
                        Apporté en magasin
                        <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </button>
                </td>
                <td v-if="hasActions" class="align-middle text-center">
                    <button type="button" class="btn btn-sm btn-success btn-block" @click="accept(order)">Accepter</button>
                    <button v-if="canMakeCounterOffer(order)" type="button" class="btn btn-sm btn-warning btn-block" @click="counterOffer(order)">Contre offre</button>
                    <button v-if="order.order_status_id != 7" type="button" class="btn btn-sm btn-warning btn-block" @click="standBy(order)">Stand by</button>
                    <button v-if="order.order_status_id == 7" type="button" class="btn btn-sm btn-warning btn-block" @click="getUserInfos(order)">Coordonnées</button>
                    <button type="button" class="btn btn-sm btn-danger btn-block" @click="refuse(order)">Refuser</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<style>

.link:hover {

    cursor: pointer;
    text-decoration: underline;
}

</style>
