export default {

    generateURIFilters(path, page, filters) {
            
        let params = Object.keys(filters).map(key => { 
        
            let value = filters[key]
        
            if (Array.isArray(value) && value.length > 0) {

                return value.map(element => key + '[]=' + element).join('&')
            }

            if (typeof value === 'number' && value === 0) {

                return key + '=' + value
            }

            if (value != null && value != '') {

                return key + '=' + encodeURIComponent(value)
            }
        })

        if (page > 1) {

            params.push('page=' + page)
        }
    
        let queryString = params.filter(param => param != null && param != '').join('&')
        let uri = path

        if (queryString != null && queryString != '')
            uri = uri + '?' + queryString

        return uri
    },
    getBodyFilters(page, filters) {

        let body = {}
        for (const [key, value] of Object.entries(filters)) { 

            if (Array.isArray(value)) {

                if (value.length > 0)
                    body[key] = value
            }
            else if (typeof(value) == 'string') {

                if (value != null && value != '')   
                    body[key] = value
            }
            else {

                if (value != null)
                    body[key] = value
            }
        }

        if (page > 1) {

            body['page'] = page
        }
        
        return body
    }
}
