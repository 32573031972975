import Vue from 'vue'
import moment from 'moment'

Vue.filter('dateDiff', value => {
  
    if (value) {
        
        let date = moment(String(value))
        let now = moment()

        return now.diff(date, 'days')+' jours'
    }
})
