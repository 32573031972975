"use strict";

import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    
    function (config) {
        
        const token = sessionStorage.getItem('user-token')
        
        if (token) {

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        }
        return config;
    },
    function (error) {

        return Promise.reject(error);
    }
)

axios.interceptors.response.use(
    
    function (response) {

        return response;
    },
    function (error) {

        if (error.response.status == 401 && window.location.pathname != '/login') {

            window.location = '/login'
        }

        return Promise.reject(error);
    }
)
