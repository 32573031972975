import Vue from 'vue'
import VueI18n from 'vue-i18n'
//import en from './en'
import fr from './fr'

Vue.use(VueI18n)
//let locale = navigator.language.split('-')[0]

export default new VueI18n({
    fallbackLocale: 'fr',
    locale: 'fr',
    messages: {
        //en: en,
        fr: fr,
    },
    numberFormats: {
        'fr': {
            currency: {
                style: 'currency',
                currency: 'EUR'
            }
        }
    }
})
