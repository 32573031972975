import store from '../../store/index'
import axios from 'axios'
export default {

    axiosRequest(path, method = 'GET', data = null, headers = {}) {

        let body = data || {};

        if (method == 'GET') {

            if (path.includes('?')) {

                path = path + '&resellerId=' + store.getters.resellerId
            }
            else {

                path = path + '?resellerId=' + store.getters.resellerId
            }
        }
        else {

            body.resellerId = store.getters.resellerId
        }

        const config = {
            method: method,
            url: path,
            data: body,
            headers: headers
        };

        return axios(config);
    }
}
