
import filtersHelper from './helpers/filters'
import requestMaker from './helpers/requestMaker'

export default {

    list(page, status) {

        let path = '/api/reseller/listings'

        let data = {

            status: status != null ? status : []
        }
        let body = filtersHelper.getBodyFilters(page, data)

        return requestMaker.axiosRequest(path, 'POST', body, {'X-HTTP-Method-Override': 'GET'})
    },
    show(listingPublicId) {

        let path = '/api/reseller/listings/'+ listingPublicId


        return requestMaker.axiosRequest(path, 'POST', {}, {'X-HTTP-Method-Override': 'GET'})
    },
    accept(totalPrice, listingPublicId) {

        
        let path = '/api/reseller/listings/'+ listingPublicId + '/accept'

        let data = {

            total_price: parseInt(totalPrice*100)
        }

        return requestMaker.axiosRequest(path, 'PATCH', data)
    },
    refuse(reason, listingPublicId) {

        let path = '/api/reseller/listings/'+ listingPublicId + '/refuse'

        let data = {

            reason: reason
        }

        return requestMaker.axiosRequest(path, 'PATCH', data)
    },
    getPhotos(listingPublicId) {

        let path = '/api/reseller/listings/'+ listingPublicId + '/photos'

        return requestMaker.axiosRequest(path, 'POST', {}, {'X-HTTP-Method-Override': 'GET'})
    },
    count() {

        let path = 'api/reseller/listings/count'

        return requestMaker.axiosRequest(path, 'POST', {}, {'X-HTTP-Method-Override': 'GET'})
    },
}
