<script type="text/ecmascript-6">

    import MainHeader from '../components/Header.vue'
    import MainFooter from '../components/Footer.vue'

    export default {
        components: {MainHeader, MainFooter}
    }
</script>
<template>
    <div>
        <main-header />
        <div class="container-fluid mt-4">
            <slot/>
        </div>
        <main-footer />
    </div>
</template>