import requestMaker from './helpers/requestMaker'

export default {

    getFilters(type) {

        let path = '/api/reseller/specsFilters' + '?type=' + type
        
        return requestMaker.axiosRequest(path)
    },
    getValues(type) {

        let path = '/api/reseller/specs' + '?type=' + type

        return requestMaker.axiosRequest(path, 'POST',{} , {'X-HTTP-Method-Override': 'GET'})
    },
    update(type, values) {

        let path = '/api/reseller/specs' + '?type=' + type

        return requestMaker.axiosRequest(path, 'PATCH', values)
    }
}
