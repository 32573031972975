export default {

    methods: {
        isTabActive(tabs, tabId) {

            for (let tab of tabs) {

                if (tabId == tab.id) {

                    return tab.isActive == true
                }
            }

            return false
        },
        toggleTabs(tabs, tabId) {

            if (tabs) {

                for (let i = 0; i < tabs.length; i++) {

                    if (tabs[i].id == tabId) {

                        tabs[i].isActive = true
                    }
                    else {

                        tabs[i].isActive = false
                    }
                }
            }
        },
        loadTab(tabs) {

            if (this.$route.hash != '') {

                this.toggleTabs(tabs, this.$route.hash.replace('#', ''))
            }
        },
    }
}
