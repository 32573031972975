import axios from 'axios'

export default {

    list(full) {

        var path = '/api/customer/categories?full=' + full

        return axios.get(path)
    }
}
