<script type="text/ecmascript-6">
export default {
    data() {
        return {

            closeOustandingAlert: false
        }
    },
    created() {

        this.closeOustandingAlert = sessionStorage.getItem('closeOustandingAlert') === 'true' ? true : false
    },
    computed: {
        displayAlert() {

            if (this.closeOustandingAlert) {

                return false
            }

            const reseller = this.$store.getters.reseller
        
            if (!reseller) return false

            return !this.isOutstandingOK(reseller)
        },
    },
    methods: {
        isOutstandingOK(reseller) {

            if (reseller.name.includes('HDO')) return true

            return (reseller.outstanding_ceil_amount + reseller.outstanding_ceil_amount_greendid) > reseller.outstanding_missing_amount
        },
        close() {

            sessionStorage.setItem('closeOustandingAlert', 'true')
            this.closeOustandingAlert = true
        }
    }
}
</script>

<template>
    <div v-if="displayAlert" class="alert alert-danger alert-dismissible text-center" role="alert">
        Votre encours a été dépassé. Pour plus de détails, <router-link :to="{name: 'settings'}">allez dans vos paramètres</router-link> et contactez votre interlocuteur commercial.
        <button type="button" class="close" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</template>
