<script type="text/ecmascript-6">
    import MainLayout from '../layouts/Main.vue'
    import Tabs from '../components/Tabs.vue'
    import tabsMixin from '../mixins/tabs'
    import orderService from '../services/order'
    import offerService from '../services/offer'
    import Orders from '../components/Orders.vue'
    import StandardModal from '../components/StandardModal.vue'
    import ImportFiles from "@/components/ImportFiles.vue"
    import * as Sentry from "@sentry/vue"
    import Paginate from 'vuejs-paginate'

    export default {
        components: { MainLayout, Tabs, Orders, StandardModal, ImportFiles, Paginate},
        mixins: [tabsMixin],
        data() {
            return {

                orders: null,
                refusedOrder: null,
                acceptedOrder: null,
                isAcceptOrderLoading: false,
                refusedMessage: '',
                counterOfferedOrder: null,
                counterOfferPrice: null,
                counterOfferMessage: '',
                resellerPhotos:[],
                counterOfferError: null,
                isCounterOfferLoading: false,
                trackingNumber: null,
                simulatedPrices: null,
                standByOrder: null,
                standByMessage: '',
                isStandByLoading: false,
                userInfos: null,
                defaultFilters: {'status': [3, 4, 7], 'resellerId': this.$store.state.resellerId  ? this.$store.state.resellerId : null},
                tabs: [
                    {
                        id: 'toValidate',
                        label: 'Commandes à valider',
                        isActive: true,
                        status: [3, 4, 7]
                    },
                    {
                        id: 'counterOffer',
                        label: 'Commandes en contre offre',
                        isActive: false,
                        status: [6]
                    },
                    {
                        id: 'running',
                        label: 'Commandes à venir',
                        isActive: false,
                        status: [2, 1]
                    },
                    {
                        id: 'done',
                        label: 'Commandes validées',
                        isActive: false,
                        status: [10, 5]
                    },
                    {
                        id: 'refused',
                        label: 'Commandes annulées',
                        isActive: false,
                        status: [9]
                    }
                ],
                allFilters: {'status': [1, 2, 3, 4, 5, 6, 7, 9, 10]},
                isLoading: false,
                specsAttributes:null,
                kycDetails:null,
                specsAttributesLoading: null,
                orderDetails: null,
                cantRefuse: null,
                countOrders : null,
                currentPage: 1,
                lastPage: null,
                filterStatus: null,
                extensions: [
                    {ext : '.jpg', char: '/'},
                    {ext : '.png', char: 'i'},
                    {ext : '.gif', char: 'R'},
                    {ext : '.webp', char: 'U'},
                    {ext : '.pdf', char: 'J'},
                ],
                extension:''
            }
        },
        methods: {

            async loadOrders(filters) {

                let response = await orderService.list(this.currentPage, filters)
                this.orders = response.data.data
                this.lastPage = response.data.last_page
            },
            async confirmAcceptOrder() {

                this.isAcceptOrderLoading = true
                await orderService.accept(this.acceptedOrder.public_id)
                this.loadOrders(this.defaultFilters)
                this.cleanOrderAccepted()
                this.isAcceptOrderLoading = false
            },
            orderAccepted(order) {

                this.acceptedOrder = order
            },
            orderRefused(order) {

                if (order.brand_campaign != undefined) {

                    this.cantRefuse = true
                }
                else {

                    this.refusedOrder = order
                }
            },
            orderStandBy(order) {

                this.standByOrder = order
            },
            orderCounterOffered(order) {

                this.counterOfferedOrder = order
                this.counterOfferPrice = order.total_price
            },
            cleanRefused() {

                this.refusedOrder = null
                this.refusedMessage = ''
                this.resellerPhotos = []
                this.simulatedPrices = null
            },
            cleanCounterOffered() {

                this.counterOfferedOrder = null
                this.counterOfferMessage = ''
                this.counterOfferPrice = null
                this.resellerPhotos = []
                this.simulatedPrices = null
            },
            cleanOrderAccepted() {

                this.acceptedOrder = null
                this.resellerPhotos = []
                this.simulatedPrices = null
            },
            cleanStandBy() {

                this.standByMessage = ''
                this.standByOrder = null
                this.resellerPhotos = []
                this.simulatedPrices = null
            },
            cleanUserInfos() {

                this.userInfos = null
            },
            cleanAttributesInfos() {

                this.specsAttributes = null
            },
            cleanKycDetails() {

                this.kycDetails = null
            },
            cleanOrderDetails() {

                this.orderDetails = null
            },
            async confirmRefuseOrder() {

                if (this.isRefusedMessageLengthOK && this.isNbrFilesOk) {

                    try {
                        
                        await orderService.refuse(this.refusedOrder.public_id, this.refusedMessage, this.resellerPhotos)
                        this.loadOrders(this.defaultFilters)
                    }
                    catch (error) {

                        Sentry.captureException(error);
                    }

                    this.cleanRefused()
                }
            },
            async confirmCounterOffer() {

                this.counterOfferError = null
                this.isCounterOfferLoading = true
                if (this.isCounterOfferMessageLengthOK && this.counterOfferPrice > 0 && this.resellerPhotos.length > 0) {

                    try {

                        await orderService.counterOffer(this.counterOfferedOrder.public_id, this.counterOfferMessage, this.counterOfferPrice, this.resellerPhotos)
                        this.loadOrders(this.defaultFilters)
                    }
                    catch (error) {

                        this.counterOfferError = 'Une erreur est survenue.'
                        this.isCounterOfferLoading = false
                        Sentry.captureException(error);
                    }

                    this.cleanCounterOffered()
                }
                this.isCounterOfferLoading = false
            },
            async confirmStandBy() {

                if (this.isStandByMessageLengthOK) {

                    await orderService.standBy(this.standByOrder.public_id, this.standByMessage)
                    this.loadOrders(this.defaultFilters)
                    this.cleanStandBy()
                }
            },
            async downloadMerchantInvoice(order) {

                let response = await orderService.getMerchantInvoice(order.public_id)
                this.extension = ''

                this.extensions.forEach(element => {
                    
                    if (element.char === response.data[0]) {

                        this.extension = element.ext
                    }
                });

                this.openFile(response.data, this.extension)
            },
            async downloadTransfer(order) {

                let response = await orderService.getTransfer(order.public_id)
                this.openPDF(response.data, 'greendid-transfer-' + order.public_id + '.pdf')
            },
            openPDF(content, fileName) {

                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                let byteCharacters = atob(content);
                let byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                let file = new Blob([byteArray], { type: 'application/pdf;base64' });
                let fileURL = URL.createObjectURL(file);
                
                a.href = fileURL;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(fileURL);
            },
            async openFile(content, extension) {

                let a = document.createElement("a")
                document.body.appendChild(a);
                a.style = "display: none"

                let byteCharacters = atob(content)
                let byteNumbers = new Array(byteCharacters.length)
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i)
                }
                let byteArray = new Uint8Array(byteNumbers)
                let type = ''
                switch (extension.toLowerCase()) {

                    case '.pdf':
                        type = 'application/pdf'
                        break
                    case '.jpeg':
                    case '.jpg':
                        type = 'image/jpeg'
                        break
                    case '.png':
                        type = 'image/png'
                        break
                    case '.gif':
                        type = 'image/gif'
                        break
                }
                let file = new Blob([byteArray], { type: type+';base64' })
                let fileURL = URL.createObjectURL(file)

                a.href = fileURL
                a.setAttribute('target', '_blank')
                a.click();
            },
            async refreshPrice(counterOfferedOrder) {

                let response = await offerService.simulatePrice(this.counterOfferPrice * 100, counterOfferedOrder.category.weight, counterOfferedOrder.reseller.delivery_type, counterOfferedOrder.merchant_id)
                this.simulatedPrices = response.data
            },
            async getUserInfos(order) {

                let response = await orderService.getUserInfos(order.public_id)
                this.userInfos = response.data
            },
            async getProductAttributes(orderPublicId) {

                this.specsAttributesLoading = true
                let response = await orderService.getProductAttributes(orderPublicId)
                this.specsAttributes = JSON.stringify(response.data);
                this.specsAttributesLoading = false
            },
            async getKycDetails(order) {

                try {

                    var response = await orderService.getUserKycDetails(order.user_id)
                }
                catch(e) {

                    console.log(e);
                }

                this.kycDetails = response.data
            },
            openAttributesModal(order) {

                if (order.attached_to === 'specs') {

                    this.specsAttributes = order.offer_specs.attributes
                }
                else if (order.attached_to === 'product') {

                    this.getProductAttributes(order.public_id)
                }
            },
            openOrderDetailsModal(order) {

                this.orderDetails = order
            },
            openZIP(content, filename) {

                const url = window.URL.createObjectURL(new Blob([content]))
                const link = document.createElement('a')
                link.href = url

                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
            },
            async deliveryAcceptedOnSite(order) {

                this.isLoading = true
                try {
                    
                    await orderService.deliveryAcceptedOnSite(order.public_id)
                }
                catch (error) {

                    console.log(error);
                }
                this.isLoading = false
                this.loadCount()
                this.loadOrders(this.defaultFilters)
            },
            displayRefuseAlert(order) {

                if (order.contribution_amount > 0) {

                    return true
                }

                return false
            },
            loadActiveOrders(tabId) {

                this.toggleTabs(this.tabs, tabId)

                for (let i = 0; i < this.tabs.length; i++) {
                    const element = this.tabs[i];

                    if (element.isActive) {

                        this.filterStatus = element.status
                    }
                }
                this.loadOrders({trackingNumber: this.trackingNumber, 'status': this.filterStatus})
            },
            async loadCount(trackingNumber) {

                let response = await orderService.countOrders(this.tabs, this.resellerId, trackingNumber)
                this.countOrders = response.data
            },
            handleTabToggled(payload) {
    
                const { tabId } = payload;
                this.loadActiveOrders(tabId)
            },
        },
        created() {

            this.loadCount()
            this.loadOrders(this.defaultFilters)
        },
        computed: {

            ordersToValidate() {

                return this.orders != undefined ? this.orders.filter(element => [3, 4, 7].includes(element.order_status_id)) : []
            },
            ordersRunning() {

                return this.orders != undefined ? this.orders.filter(element => [1, 2].includes(element.order_status_id)) : []
            },
            ordersDone() {

                return this.orders != undefined ? this.orders.filter(element => [5, 10].includes(element.order_status_id)).reverse() : []
            },
            ordersRefused() {

                return this.orders != undefined ? this.orders.filter(element => [9].includes(element.order_status_id)) : []
            },
            ordersCounterOffer() {

                return this.orders != undefined ? this.orders.filter(element => [6].includes(element.order_status_id)) : []
            },
            countOrdersToValidate() {

                return this.countOrders ? this.countOrders.filter(element => element.label === 'Commandes à valider')[0].count : ''
            },
            countOrdersRunning() {

                return this.countOrders ? this.countOrders.filter(element => element.label === 'Commandes à venir')[0].count : ''
            },
            countOrdersDone() {

                return this.countOrders ? this.countOrders.filter(element => element.label === 'Commandes validées')[0].count : ''
            },
            countOrdersRefused() {

                return this.countOrders ? this.countOrders.filter(element => element.label === 'Commandes annulées')[0].count : ''
            }, 
            countOrdersCounterOffer() {

                return this.countOrders ? this.countOrders.filter(element => element.label === 'Commandes en contre offre')[0].count : ''
            }, 
            isRefusedOrderLoaded() {

                return this.refusedOrder != null ? true : false
            },
            isRefusedMessageLengthOK() {

                return this.refusedMessage.length > 10
            },
            isNbrFilesOk() {

                return this.resellerPhotos.length > 0
            },
            isCounterOfferMessageLengthOK() {

                return this.counterOfferMessage.length > 10
            },
            isStandByMessageLengthOK() {

                return this.standByMessage.length > 10
            },
            attributesParsed() {

                
                return this.specsAttributes != undefined ? JSON.parse(this.specsAttributes) : null
            },
            trackingNumberLenghtOK() {

                return this.trackingNumber && this.trackingNumber.length > 5
            },
            resellerId() {

                return this.$store.state.resellerId != null ? this.$store.state.resellerId : null
            }
        },
        watch: {

            trackingNumber() {

                if (this.trackingNumberLenghtOK) {

                    this.loadCount(this.trackingNumber)
                    this.loadOrders({trackingNumber: this.trackingNumber, status: this.allFilters.status})
                }

                if (this.trackingNumber.length == 0) {

                    this.loadCount()
                    this.loadOrders(this.defaultFilters)
                }
            }
        }
    }
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
<template>
    <main-layout>
        <h3>Commandes</h3>
        <tabs v-model="tabs" @tabToggled="handleTabToggled" />
        <div class="row mx-n2 pt-2">
            <div class="col-md-2 col-sm-6 px-2 mb-4">
                <a href="#toValidate" v-on:click="loadActiveOrders('toValidate')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'toValidate'), 'border-primary': isTabActive(tabs, 'toValidate')}">
                        <h3 class="font-size-sm text-muted">Commandes à valider</h3>
                        <p v-if="countOrdersToValidate != null" class="h2 mb-2 text-success">{{countOrdersToValidate}}</p>
                        <span v-else class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'toValidate')">Voir le détail</p>
                    </div>
                </a>
            </div>
            <div class="col-md-2 col-sm-6 px-2 mb-4">
                <a href="#counterOffer" v-on:click="loadActiveOrders('counterOffer')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'counterOffer'), 'border-primary': isTabActive(tabs, 'counterOffer')}">
                        <h3 class="font-size-sm text-muted">Commandes en contre offre</h3>
                        <p v-if="countOrdersCounterOffer != null"  class="h2 mb-2 text-success">{{countOrdersCounterOffer}}</p>
                        <span v-else class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'counterOffer')">Voir le détail</p>
                    </div>
                </a>
            </div>
            <div class="col-md-2 col-sm-6 px-2 mb-4">
                <a href="#running" v-on:click="loadActiveOrders('running')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'running'), 'border-primary': isTabActive(tabs, 'running')}">
                        <h3 class="font-size-sm text-muted">Commandes à venir</h3>
                        <p v-if="countOrdersRunning != null" class="h2 mb-2 text-success">{{countOrdersRunning}}</p>
                        <span v-else class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'running')">Voir le détail</p>
                    </div>
                </a>
            </div>
            <div class="col-md-2 col-sm-12 px-2 mb-4">
                <a href="#done" v-on:click="loadActiveOrders('done')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'done'), 'border-primary': isTabActive(tabs, 'done')}">
                        <h3 class="font-size-sm text-muted">Commandes validées</h3>
                        <p v-if="countOrdersDone != null" class="h2 mb-2 text-success">{{countOrdersDone}}</p>
                        <span v-else class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'done')">Voir le détail</p>
                    </div>
                </a>
            </div>
            <div class="col-md-2 col-sm-12 px-2 mb-4">
                <a href="#refused" v-on:click="loadActiveOrders('refused')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'refused'), 'border-primary': isTabActive(tabs, 'refused')}">
                        <h3 class="font-size-sm text-muted">Commandes annulées</h3>
                        <p v-if="countOrdersRefused != null" class="h2 mb-2 text-success">{{countOrdersRefused}}</p>
                        <span v-else class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'refused')">Voir le détail</p>
                    </div>
                </a>
            </div>
        </div>
        <form v-on:submit.prevent>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="col form-group">
                            <input class="cz-filter-search form-control" :class="trackingNumberLenghtOK ? 'border-success' : ''" v-model="trackingNumber" type="text" placeholder="Numéro de tracking">
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <nav class="mb-3" aria-label="pagination" @click="loadOrders({'status': filterStatus})">
            <paginate v-if="lastPage != null"
                v-model="currentPage"
                :pageCount="lastPage"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :prevText="'<i class=\'czi-arrow-left\'></i> Précédent'"
                :nextText="'Suivant <i class=\'czi-arrow-right\'></i>'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :nextClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextLinkClass="'page-link'"
            />
        </nav>
        <div class="tab-content">
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'toValidate'), active: isTabActive(tabs, 'toValidate')}" id="toValidate" role="tabpanel">
                <orders v-if="ordersToValidate" :currentTab="'ordersToValidate'" :orders="ordersToValidate" :hasActions="true" :canDownloadFiles="false" @orderCounterOffered="orderCounterOffered" :canDownloadInvoice="true" @orderAccepted="orderAccepted" @orderRefused="orderRefused" @orderStandBy="orderStandBy" @getUserInfos="getUserInfos" @AttributesClicked="openAttributesModal" @OrderPublicIdClicked="openOrderDetailsModal" @merchantInvoiceClicked="downloadMerchantInvoice"/>
            </div>
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'counterOffer'), active: isTabActive(tabs, 'counterOffer')}" id="counterOffer" role="tabpanel">
                <orders v-if="ordersCounterOffer" :currentTab="'ordersToCounterOffer'" :orders="ordersCounterOffer" :hasActions="false" :canDownloadFiles="false" :canDownloadInvoice="false"  @OrderPublicIdClicked="openOrderDetailsModal" @AttributesClicked="openAttributesModal" />
            </div>
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'running'), active: isTabActive(tabs, 'running')}" id="running" role="tabpanel">
                <orders v-if="ordersRunning" :currentTab="'ordersRunning'" :orders="ordersRunning" :hasActions="false" :canDownloadFiles="false" :canDownloadInvoice="false"  @acceptedOnSite="deliveryAcceptedOnSite" @OrderPublicIdClicked="openOrderDetailsModal" @AttributesClicked="openAttributesModal"/>
            </div>
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'done'), active: isTabActive(tabs, 'done')}" id="done" role="tabpanel">
                <orders v-if="ordersDone" :currentTab="'ordersDone'" :orders="ordersDone" :hasActions="false" :canDownloadFiles="true" :canDownloadInvoice="false"  @IdentityProofClicked="getKycDetails"  @OrderPublicIdClicked="openOrderDetailsModal" @AttributesClicked="openAttributesModal" @transferClicked="downloadTransfer" />
            </div>
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'refused'), active: isTabActive(tabs, 'refused')}" id="refused" role="tabpanel">
                <orders v-if="ordersRefused" :currentTab="'ordersRefused'" :orders="ordersRefused" :hasActions="false" :canDownloadFiles="false" :canDownloadInvoice="false" @OrderPublicIdClicked="openOrderDetailsModal" @AttributesClicked="openAttributesModal" />
            </div>
        </div>
        <div v-if="isRefusedOrderLoaded">
            <transition name="modal">
                <div  class="modal-mask">
                    <div class="modal-wrapper">
                        <div  class="modal-dialog modal-lg" role="document">
                            <div  class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Refuser la commande #{{ refusedOrder.public_id }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" @click="cleanRefused()">&times;</span>
                                    </button>
                                </div>
                                <div style="overflow-y: auto;height:80vh" class="modal-body">
                                    <p>Refuser le produit "{{ refusedOrder.label }}"</p>
                                    <div class="alert alert-primary alert-sm mt-2" v-if="displayRefuseAlert(refusedOrder)">Attention, vous ne pouvez refuser le produit que si il est dans un état "correct" ou "endommagé"</div>
                                    <div class="form-group">
                                        <label for="refusedMessage">Veuillez indiquer la raison de votre refus, et au moins une photo témoignant de l'état du produit :</label>
                                        <textarea spellcheck="true" lang="fr" class="form-control" id="refusedMessage" v-model="refusedMessage" placeholder="raison du refus"></textarea>
                                    </div>
                                    <div v-if="!isRefusedMessageLengthOK" class="alert alert-primary alert-sm mt-2" role="alert">
                                        Merci de mettre au moins 10 caractères.
                                    </div>
                                    <import-files @updateFiles="resellerPhotos = $event" :files="resellerPhotos" ></import-files>
                                    <div v-if="!isNbrFilesOk" class="alert alert-primary alert-sm mt-2" role="alert">
                                        Merci d'importer au moins une photo.
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-info" @click="cleanRefused()">Annuler</button>
                                    <button type="button" class="btn btn-primary" @click="confirmRefuseOrder">Refuser</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <standard-modal v-if="acceptedOrder != undefined" :closeLabel="'Annuler'" :title="'Accepter le produit'" @close="cleanOrderAccepted">
            <template v-slot:body>
                <p>Confirmez l'acceptation du produit {{acceptedOrder.label}} ?</p>
            </template>
            <template v-slot:footer>
                <button role="button" :disabled="isAcceptOrderLoading" class="btn btn-success" @click="confirmAcceptOrder">
                    <span v-if="isAcceptOrderLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Accepter
                </button>
            </template>
        </standard-modal>
        <standard-modal v-if="counterOfferedOrder != undefined" :closeLabel="'Annuler'" :title="'Faire une contre offre'" @close="cleanCounterOffered">
            <template v-slot:body>
                <p>
                    Faire une contre offre sur le produit "{{counterOfferedOrder.label}}"
                    <span class="font-size-xs text-danger" v-if="counterOfferedOrder.source_type == 'store'"><br/><i class="czi-store" title="Magasin" /> Reprise faite en magasin</span>
                </p>
                <div class="form-group">
                    <label>Offre initiale : <strong>{{ $n(counterOfferedOrder.total_price, 'currency') }}</strong></label>
                </div>
                <div class="form-inline mb-2">
                    <label for="counterOfferPrice">Nouvelle offre :</label>
                    <input @change="refreshPrice(counterOfferedOrder)" class="form-control text-right ml-2" v-model="counterOfferPrice" id="counterOfferPrice" type="number" min="0" />
                </div>
                <div class="form-group">
                    <label>Nouveau prix d'achat vendeur : <strong>{{ simulatedPrices != null ? $n(simulatedPrices.price / 100, 'currency') : counterOfferedOrder.price }}</strong></label>
                </div>
                <div class="form-group">
                    <label for="counterOfferMessage">Veuillez indiquer la raison de votre contre offre, et au moins une photo témoignant de l'état du produit :</label>
                    <textarea spellcheck="true" lang="fr" class="form-control" id="counterOfferMessage" v-model="counterOfferMessage" placeholder="raison de la contre offre"></textarea>
                </div>
                <div v-if="!isCounterOfferMessageLengthOK" class="alert alert-primary alert-sm mt-2" role="alert">
                    Merci de mettre au moins 10 caractères.
                </div>
                <div v-if="parseFloat(counterOfferedOrder.total_price) <= parseFloat(counterOfferPrice)" class="alert alert-primary alert-sm mt-2" role="alert">
                    Votre nouvelle offre doit être inférieur à l'offre initiale.
                </div>
                <div v-if="simulatedPrices && simulatedPrices.price == 0" class="alert alert-primary alert-sm mt-2" role="alert">
                    Votre nouvelle offre est trop basse.
                </div>
                <div v-if="counterOfferError" class="alert alert-primary alert-sm mt-2" role="alert">
                    {{counterOfferError}}
                </div>
                <import-files @updateFiles="resellerPhotos = $event" :files="resellerPhotos" ></import-files>
                <div v-if="!isNbrFilesOk" class="alert alert-primary alert-sm mt-2" role="alert">
                    Merci d'importer au moins une photo.
                </div>
            </template>
            <template v-slot:footer>
                <button role="button" :disabled="!isCounterOfferMessageLengthOK || parseFloat(counterOfferedOrder.total_price) <= parseFloat(counterOfferPrice) || isCounterOfferLoading || (simulatedPrices && simulatedPrices.price == 0)" class="btn btn-danger" @click="confirmCounterOffer">
                    <span v-if="isCounterOfferLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Valider
                </button>
            </template>
        </standard-modal>
        <standard-modal v-if="standByOrder != undefined" :closeLabel="'Annuler'" :title="'Mettre en stand by'" @close="cleanStandBy">
            <template v-slot:body>
                <p>Mettre en stand by le produit "{{standByOrder.label}}"</p>
                <div class="form-group">
                    <label for="standByOfferMessage">Veuillez indiquer la raison de la mise en stand by :</label>
                    <textarea class="form-control" id="standByOfferMessage" v-model="standByMessage" placeholder="raison du stand by"></textarea>
                </div>
                <div v-if="!isStandByMessageLengthOK" class="alert alert-primary alert-sm mt-2" role="alert">
                    Merci de mettre au moins 10 caractères.
                </div>
            </template>
            <template v-slot:footer>
                <button role="button" :disabled="!isStandByMessageLengthOK || isStandByLoading" class="btn btn-danger" @click="confirmStandBy">
                    <span v-if="isStandByLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Valider
                </button>
            </template>
        </standard-modal>
        <standard-modal v-if="userInfos != undefined" :closeLabel="'Fermer'" :title="'Coordonnées'" @close="cleanUserInfos">
            <template v-slot:body>
                <p>Nom : {{userInfos.first_name}} {{userInfos.last_name}}</p>
                <p>Téléphone : {{userInfos.phone_number}}</p>
            </template>
        </standard-modal>
        <standard-modal v-if="specsAttributes != undefined || specsAttributesLoading" :closeLabel="'Fermer'" :title="'Attributs'" @close="cleanAttributesInfos">
            <template v-slot:body>
                <table class="table" v-bind:key="index" v-for="(value, key, index) in attributesParsed">
                    <span v-if="specsAttributesLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    <tbody>
                        <tr>
                            <th class="col-3 ">{{ key }}</th>
                            <td class="align-self-end col-7">{{ value }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </standard-modal>
        <standard-modal v-if="kycDetails != undefined" :closeLabel="'Fermer'" :title="'Détails de la pièce d\'identité'" @close="cleanKycDetails">
            <template v-slot:body>
                <table class="table" v-bind:key="index" v-for="(value, key, index) in kycDetails">
                    <tbody>
                        <tr v-if="value">
                            <th class="col-3 ">{{ $t('KYC.' + key) }}</th>
                            <td class="align-self-end col-7">{{value}}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </standard-modal>
        <standard-modal v-if="cantRefuse" :closeLabel="'Fermer'" :title="'Impossible de refuser cette reprise'" @close="cantRefuse = false">
            <template v-slot:body>
                <p>Cette reprise est liée à une opération commerciale, vous ne pouvez pas la refuser.<br/>Vous pouvez cependant faire une contre offre, même très basse.<br/>En cas de besoin vous pouvez contacter l'équipe Greendid pour en discuter.</p>
            </template>
        </standard-modal>
        <standard-modal v-if="orderDetails != undefined" :closeLabel="'Fermer'" :title="'Détails de la reprise'" @close="cleanOrderDetails">
            <template v-slot:body>
                <table class="table" >
                    <tbody>
                        <tr>
                            <th class="col-3 ">Public Id</th>
                            <td class="align-self-end col-7">{{orderDetails.public_id}}</td>
                        </tr>
                        <tr>
                            <th class="col-3 ">Label</th>
                            <td class="align-self-end col-7">{{orderDetails.label}}</td>
                        </tr>
                        <tr>
                            <th class="col-3 ">Marque</th>
                            <td class="align-self-end col-7">{{orderDetails.brand_name}}</td>
                        </tr>
                        <tr>
                            <th class="col-3 ">Condition</th>
                            <td class="align-self-end col-7">{{orderDetails.condition.name}}</td>
                        </tr>
                        <tr>
                            <th class="col-3 ">Prix total</th>
                            <td class="align-self-end col-7">{{orderDetails.total_price}}</td>
                        </tr>
                        <tr>
                            <th class="col-3 ">Prix</th>
                            <td class="align-self-end col-7">{{orderDetails.price}}</td>
                        </tr>
                        <tr v-if="orderDetails.refused_reason !=''">
                            <th class="col-3 ">Raison du refus</th>
                            <td class="align-self-end col-7">{{orderDetails.refused_reason}}</td>
                        </tr>
                        <tr v-if="orderDetails.active_counter_offer">
                            <th class="col-3 ">Raison de la contre-offre</th>
                            <td class="align-self-end col-7">{{orderDetails.active_counter_offer.reason}}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </standard-modal>
        <nav class="mb-3" aria-label="pagination" @click="loadOrders({'status': filterStatus})">
            <paginate v-if="lastPage != null"
                v-model="currentPage"
                :pageCount="lastPage"
                :containerClass="'pagination'"
                :pageClass="'page-item'"
                :prevText="'<i class=\'czi-arrow-left\'></i> Précédent'"
                :nextText="'Suivant <i class=\'czi-arrow-right\'></i>'"
                :pageLinkClass="'page-link'"
                :prevClass="'page-item'"
                :nextClass="'page-item'"
                :prevLinkClass="'page-link'"
                :nextLinkClass="'page-link'"
            />
        </nav>
    </main-layout>
</template>

<style>

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    max-height: 60vh;
    overflow-y: auto;
}

</style>
