export default {
    STATUS: {
        STATUS_1: 'A compléter<br/>par le vendeur',
        STATUS_2: 'A expédier<br/>par le vendeur',
        STATUS_3: 'Expédiée',
        STATUS_4: 'A valider',
        STATUS_51: 'Commande validée',
        STATUS_52: 'Commande facturée',
        STATUS_6: 'Contre offre',
        STATUS_7: 'Contact vendeur nécessaire',
        STATUS_9: 'Refusée',
        STATUS_10: 'Terminée'
    },
    STATUS_INFO: {
        STATUS_1: 'Le vendeur doit encore signer le bon de cession pour confirmer l’offre.',
        STATUS_2: 'Le vendeur n’a pas encore expédié le produit.',
        STATUS_3: 'Le produit est en cours de livraison.',
        STATUS_4: 'Contrôle du produit à effectuer.',
        STATUS_51: 'Intégrée au prochain batch de facturation',
        STATUS_52: 'Intégrée à une facture',
        STATUS_6: 'En attente de réponse du vendeur.',
        STATUS_7: 'Contact vendeur nécessaire',
        STATUS_9: 'Produit à retourner',
        STATUS_10: 'Terminée'
    },
    ATTRIBUTE_NAME: {
        ram: 'RAM',
        diagonal: 'Diagonale',
        hard_drive: 'Disque dur',
        gpu: 'Carte graphique',
        processor_generation: 'Génération de processeur',
        processor_family: 'Famille de processeur',
        brand: 'Marque',
        isWorking: 'Fonctionnel',
        condition: 'Condition',
        display_type: 'Ecran',
        isComplete: 'Complet',
        monitor_diagonal: 'Diagonale',
        monitor_screen_type: 'Type de dalle',
        monitor_max_resolution: 'Résolution max',
        monitor_connectors: 'Connectique',
        monitor_curve_type: 'Ecran incurvé',
        monitor_has_speakers: 'Hauts-parleurs'
    },
    LISTING_STATUS: {
        STATUS_to_validate: 'A valider',
        STATUS_accepted: 'Acceptée',
        STATUS_refused: 'Refusée',
    },
    KYC: {
        identity_type: "Type de pièce",
        first_name: "Prénom",
        last_name: "Nom de famille",
        number: "Numéro",
        delivery_date: "Date de délivrance",
        country: "Pays"
    }
}
